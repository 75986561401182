import { emptyCustomizedScenarioApi as api } from './emptyApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postCustomizedBomScenario: build.mutation<PostCustomizedBomScenarioApiResponse, PostCustomizedBomScenarioApiArg>({
      query: (queryArg) => ({
        url: `/v1/customized-scenario/bom`,
        method: 'POST',
        body: queryArg.customizedBomScenario,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getAllCustomizedBasicScenario: build.query<
      GetAllCustomizedBasicScenarioApiResponse,
      GetAllCustomizedBasicScenarioApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/customized-scenario/`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    postCustomizedBasicScenario: build.mutation<
      PostCustomizedBasicScenarioApiResponse,
      PostCustomizedBasicScenarioApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/customized-scenario/`,
        method: 'POST',
        body: queryArg.customizedBasicScenario,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    copyScenarioCustomizedProcess: build.mutation<
      CopyScenarioCustomizedProcessApiResponse,
      CopyScenarioCustomizedProcessApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/customized-process/copy/${queryArg.oldScenarioId}/new/${queryArg.newScenarioId}`,
        method: 'POST',
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    upsertCustomizedProcess: build.mutation<UpsertCustomizedProcessApiResponse, UpsertCustomizedProcessApiArg>({
      query: (queryArg) => ({
        url: `/v1/customized-process/`,
        method: 'POST',
        body: queryArg.customizedProcess,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    deleteCustomizedProcess: build.mutation<DeleteCustomizedProcessApiResponse, DeleteCustomizedProcessApiArg>({
      query: (queryArg) => ({
        url: `/v1/customized-process/`,
        method: 'DELETE',
        body: queryArg.objectId,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getCustomizedBasicScenario: build.query<GetCustomizedBasicScenarioApiResponse, GetCustomizedBasicScenarioApiArg>({
      query: (queryArg) => ({
        url: `/v1/customized-scenario/${queryArg.scenarioId}`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getCustomizedBasicScenariosLastUpdate: build.query<
      GetCustomizedBasicScenariosLastUpdateApiResponse,
      GetCustomizedBasicScenariosLastUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/customized-scenario/lastUpdate`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getCustomizedBom: build.query<GetCustomizedBomApiResponse, GetCustomizedBomApiArg>({
      query: (queryArg) => ({
        url: `/v1/customized-scenario/bom/${queryArg.scenarioId}`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getCustomizedProcess: build.query<GetCustomizedProcessApiResponse, GetCustomizedProcessApiArg>({
      query: (queryArg) => ({
        url: `/v1/customized-process/${queryArg.scenarioId}`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    deleteCustomizedProcessByScenarioId: build.mutation<
      DeleteCustomizedProcessByScenarioIdApiResponse,
      DeleteCustomizedProcessByScenarioIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/customized-process/scenario/${queryArg.scenarioId}`,
        method: 'DELETE',
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as customizedScenarioApi };
export type PostCustomizedBomScenarioApiResponse = /** status 200 OK */ CustomizedBomScenario;
export type PostCustomizedBomScenarioApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  customizedBomScenario: CustomizedBomScenario;
};
export type GetAllCustomizedBasicScenarioApiResponse = /** status 200 OK */ CustomizedBasicScenario[];
export type GetAllCustomizedBasicScenarioApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type PostCustomizedBasicScenarioApiResponse = /** status 200 OK */ CustomizedBasicScenario;
export type PostCustomizedBasicScenarioApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  customizedBasicScenario: CustomizedBasicScenario;
};
export type CopyScenarioCustomizedProcessApiResponse = /** status 200 OK */ CustomizedProcess;
export type CopyScenarioCustomizedProcessApiArg = {
  oldScenarioId: string;
  newScenarioId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type UpsertCustomizedProcessApiResponse = /** status 200 OK */ CustomizedProcess;
export type UpsertCustomizedProcessApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  customizedProcess: CustomizedProcess;
};
export type DeleteCustomizedProcessApiResponse = /** status 200 OK */ string;
export type DeleteCustomizedProcessApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  objectId: ObjectId;
};
export type GetCustomizedBasicScenarioApiResponse = /** status 200 OK */ CustomizedBasicScenario;
export type GetCustomizedBasicScenarioApiArg = {
  scenarioId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type GetCustomizedBasicScenariosLastUpdateApiResponse = /** status 200 OK */ CustomizedScenarioDto[];
export type GetCustomizedBasicScenariosLastUpdateApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type GetCustomizedBomApiResponse = /** status 200 OK */ CustomizedBomScenario;
export type GetCustomizedBomApiArg = {
  scenarioId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type GetCustomizedProcessApiResponse = /** status 200 OK */ CustomizedProcess;
export type GetCustomizedProcessApiArg = {
  scenarioId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type DeleteCustomizedProcessByScenarioIdApiResponse = /** status 200 OK */ string;
export type DeleteCustomizedProcessByScenarioIdApiArg = {
  scenarioId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type ObjectId = {
  timestamp?: number;
  date?: string;
};
export type PhaseTree = {
  phaseId?: string;
  treeId?: string;
};
export type AssessmentConfiguration = {
  impactMethodRefId?: string;
  assessmentType?: string;
  phaseConfigurations?: PhaseTree[];
};
export type LinkedParameter = {
  parameterName: string;
  value: string;
};
export type Option = {
  label: string;
  value: string;
  isDefault?: boolean;
  linkedParameters?: LinkedParameter[];
};
export type Validation = {
  type?: string;
  value?: number;
  message?: string;
};
export type DisplayOption = {
  phaseId?: string;
  tab?: string;
  group?: string;
  item?: string;
  formulaParameter?: string;
};
export type Parameter = {
  alias?: string;
  value?: string;
  item?: string;
  description?: string;
  tooltip?: string;
  parameterName: string;
  options?: Option[];
  validations?: Validation[];
  unitOfMeasure?: string;
  type: 'AMOUNT_CHANGE' | 'FLOW_SWITCH' | 'ADDITIONAL_PARAMETER';
  parameterType: 'NUMERIC' | 'STRING' | 'OPTION' | 'FORMULA' | 'REMOTE' | 'LIST';
  hidden?: string;
  editable?: string;
  display?: DisplayOption;
};
export type FlowSwitchParameter = Parameter & {
  parentRefId?: string;
  rootModeledProcessRefId?: string;
};
export type Dependency = {
  parameterName?: string;
};
export type FormulaParameter = Parameter & {
  formula?: string;
  dependsOn?: Dependency[];
  accuracy?: number;
};
export type ItemProperty = {
  name?: string;
};
export type ListParameter = Parameter & {
  listItem?: ItemProperty[];
};
export type RemoteParameter = Parameter & {
  flowId?: string;
};
export type CustomizedBasicScenario = {
  created?: string;
  lastUpdated?: string;
  createdBy?: string;
  realm?: string;
  scenarioId?: string;
  id?: ObjectId;
  assessmentConfigurations?: AssessmentConfiguration[];
  parameters?: (FlowSwitchParameter | FormulaParameter | ListParameter | RemoteParameter)[];
};
export type CustomizedItem = {
  id?: ObjectId;
  itemPath?: string;
  itemId?: string;
  customizedBasicScenario?: CustomizedBasicScenario;
  root?: boolean;
};
export type CustomizedBomScenario = {
  created?: string;
  lastUpdated?: string;
  createdBy?: string;
  realm?: string;
  scenarioId?: string;
  id?: ObjectId;
  bomId?: string;
  assessmentConfigurations?: AssessmentConfiguration[];
  customizedItems?: CustomizedItem[];
};
export type CustomizedProcess = {
  created?: string;
  lastUpdated?: string;
  createdBy?: string;
  realm?: string;
  id?: ObjectId;
  refId?: string;
  customization?: object;
};
export type CustomizedScenarioDto = {
  scenarioId?: string;
};
export const {
  usePostCustomizedBomScenarioMutation,
  useGetAllCustomizedBasicScenarioQuery,
  usePostCustomizedBasicScenarioMutation,
  useCopyScenarioCustomizedProcessMutation,
  useUpsertCustomizedProcessMutation,
  useDeleteCustomizedProcessMutation,
  useGetCustomizedBasicScenarioQuery,
  useGetCustomizedBasicScenariosLastUpdateQuery,
  useGetCustomizedBomQuery,
  useGetCustomizedProcessQuery,
  useDeleteCustomizedProcessByScenarioIdMutation,
} = injectedRtkApi;
