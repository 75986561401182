import { HiArrowPath, HiPlusCircle } from 'react-icons/hi2';
import classNames from '../../../utils/classNames';
import { Roled } from '../../roles/hooks/Roled';
import { Link } from '../../../components/Elements';
import { LiaCubeSolid } from 'react-icons/lia';
import { RiDeleteBinLine, RiEditBoxLine } from 'react-icons/ri';
import { GrDuplicate } from 'react-icons/gr';
import React from 'react';

type CreateScenarioButtonProps = {
  size?: string;
  className?: string;
  color?: 'dark-gray' | 'white' | 'light-gray';
  to: string;
  disabled?: boolean;
};

export const CreateScenarioButton = (props: CreateScenarioButtonProps) => {
  const { className, to, disabled } = props;

  return (
    <div className={className}>
      <Link
        to={to}
        disabled={disabled}
        /*className={'absolute flex w-full h-full text-gray-600 cursor-pointer backdrop-blur-[3px] content-center items-center text-center hover:bg-blue-100 hover:bg-opacity-60 hover:text-blue-600 hover:border-blue-400'}>*/
        className={
          'absolute flex w-full h-full text-gray-600 cursor-pointer bg-opacity-60 bg-white content-center items-center text-center hover:bg-blue-100 hover:bg-opacity-90 hover:text-blue-600 hover:border-blue-400 border border-gray-800 border-dashed disabled:border-0'
        }
      >
        <div className="absolute left-[40%]">
          <HiPlusCircle className="h-6 w-6 mr-2 float-left" /> Create New Scenario
        </div>
      </Link>

      <div className="block col-span-9 hover:bg-blue-100 p-4 focus:outline-none bg-white">
        <div className="grid grid-cols-12">
          <div className="col-span-1">
            <LiaCubeSolid className="h-6 w-6" />
          </div>
          <p
            className={classNames(
              'col-span-7 truncate text-sm font-medium',
              disabled ? 'text-gray-500' : 'text-blue-600',
            )}
          >
            Scenario name
          </p>
          <div className="col-span-2 flex py-1"></div>
          <p
            className={classNames(
              'col-span-2 h-5 m-1 text-center content-center rounded px-2 leading-5',
              disabled ? 'bg-gray-300' : 'bg-green-100',
            )}
          />
        </div>
        <div className="grid grid-cols-12">
          <div className="col-start-2 col-span-9 sm:justify-between">
            <p className="whitespace-wrap flex items-center text-sm text-gray-500 pr-2">Description</p>
          </div>
          <div className="mt-2 col-span-2 text-center content-center text-sm text-gray-500 sm:mt-0">
            <p>{new Date().toDateString()}</p>
          </div>
        </div>
      </div>
      <div className="flex col-span-1 items-center text-center justify-center bg-white">
        <RiEditBoxLine
          className={classNames('h-6 w-6 m-auto focus:outline-none', disabled ? 'text-gray-500' : 'text-blue-600')}
        />
      </div>

      <div className="flex col-span-1 items-center text-center justify-center bg-white">
        <GrDuplicate
          className={classNames('h-5 w-5 m-auto focus:outline-none', disabled ? 'text-gray-500' : 'text-blue-600')}
        />
      </div>

      <div className="flex col-span-1 items-center text-center justify-center bg-white">
        <RiDeleteBinLine className={classNames('h-6 w-6 m-auto', disabled ? 'text-gray-500' : 'text-red-500')} />
      </div>
    </div>
  );
};
