import React, { useEffect } from 'react';
import { Link } from '../../../components/Elements';
import { useBreadcrumbs } from '../../../hooks/breadcrumbs';
import { HiPlusCircle } from 'react-icons/hi2';
import { RiDeleteBinLine } from 'react-icons/ri';
import ListPermissions from '../components/ListPermissions';

export const Permissions = () => {
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([{ label: 'permissions' }]);
  }, []);

  return (
    <div className="flex flex-col mt-3">
      <div className="relative grid grid-cols-12 flex border border-gray-400 border-dashed border-spacing-2">
        <Link
          to={'/app/permissions/new`'}
          /*className={'absolute flex w-full h-full text-gray-600 cursor-pointer content-center items-center text-center backdrop-blur-[4px] hover:bg-blue-100 hover:bg-opacity-80 hover:text-blue-600 hover:border-blue-400'}>*/
          className={
            'absolute flex w-full h-full text-gray-600 cursor-pointer bg-opacity-60 bg-white content-center items-center text-center hover:bg-blue-100 hover:bg-opacity-90 hover:text-blue-600 hover:border-blue-400'
          }
        >
          <div className="absolute left-[40%]">
            <HiPlusCircle className="h-6 w-6 mr-2 float-left" /> Create New Permission
          </div>
        </Link>

        <div className="block col-span-10 hover:bg-blue-50 p-4 bg-white">
          <div className="flex items-center justify-between">
            <p className="truncate text-sm font-medium text-blue-600">New Permission name</p>
          </div>
          <div className="mt-2 sm:flex sm:justify-between">
            <div className="sm:flex">
              <p className="flex items-center text-sm text-gray-500">Permission label</p>
            </div>
          </div>
        </div>
        <div
          className={
            'justify-center flex col-span-2 items-center hover:bg-red-100 border-0 text-red-500 rounded-none bg-white'
          }
        >
          <RiDeleteBinLine className="h-6 w-6" />
        </div>
      </div>

      <ListPermissions />
    </div>
  );
};
