import { emptyAdvisoryApi as api } from './emptyApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    rootV1HealthCheckGet: build.query<RootV1HealthCheckGetApiResponse, RootV1HealthCheckGetApiArg>({
      query: () => ({ url: `/v1/health-check` }),
    }),
    executeSmosCalculationV1SmosExecuteOptimizationPost: build.mutation<
      ExecuteSmosCalculationV1SmosExecuteOptimizationPostApiResponse,
      ExecuteSmosCalculationV1SmosExecuteOptimizationPostApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/smos/execute-optimization`,
        method: 'POST',
        headers: {
          'x-realm': queryArg['x-realm'],
          'x-group': queryArg['x-group'],
          'x-role': queryArg['x-role'],
          'x-user': queryArg['x-user'],
        },
        params: {
          algorithm: queryArg.algorithm,
          scenario_id: queryArg.scenarioId,
          number_of_scenarios: queryArg.numberOfScenarios,
          sustainability_percentage: queryArg.sustainabilityPercentage,
          profit_percentage: queryArg.profitPercentage,
          indicators: queryArg.indicators,
          scenario_title: queryArg.scenarioTitle,
          scenario_description: queryArg.scenarioDescription,
        },
      }),
    }),
    createCombinationTreeV1SmosCreateCombinationTreeTemplateIdPost: build.mutation<
      CreateCombinationTreeV1SmosCreateCombinationTreeTemplateIdPostApiResponse,
      CreateCombinationTreeV1SmosCreateCombinationTreeTemplateIdPostApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/smos/create-combination-tree/${queryArg.templateId}`,
        method: 'POST',
        headers: {
          'x-realm': queryArg['x-realm'],
          'x-group': queryArg['x-group'],
          'x-role': queryArg['x-role'],
          'x-user': queryArg['x-user'],
        },
      }),
    }),
    calculateLinkedCombinationsV1SmosCalculateLinkedCombinationsPost: build.mutation<
      CalculateLinkedCombinationsV1SmosCalculateLinkedCombinationsPostApiResponse,
      CalculateLinkedCombinationsV1SmosCalculateLinkedCombinationsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/smos/calculate-linked-combinations`,
        method: 'POST',
        body: queryArg.body,
        headers: {
          'x-realm': queryArg['x-realm'],
          'x-group': queryArg['x-group'],
          'x-role': queryArg['x-role'],
          'x-user': queryArg['x-user'],
        },
        params: { scenario_id: queryArg.scenarioId },
      }),
    }),
    hasCombinationTreeV1SmosHasCombinationTreeTemplateIdGet: build.query<
      HasCombinationTreeV1SmosHasCombinationTreeTemplateIdGetApiResponse,
      HasCombinationTreeV1SmosHasCombinationTreeTemplateIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/smos/has_combination_tree/${queryArg.templateId}`,
        headers: {
          'x-realm': queryArg['x-realm'],
          'x-group': queryArg['x-group'],
          'x-role': queryArg['x-role'],
          'x-user': queryArg['x-user'],
        },
      }),
    }),
    getLinkedParametersV1SmosLinkedParametersGet: build.query<
      GetLinkedParametersV1SmosLinkedParametersGetApiResponse,
      GetLinkedParametersV1SmosLinkedParametersGetApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/smos/linked-parameters`,
        headers: {
          'x-realm': queryArg['x-realm'],
          'x-group': queryArg['x-group'],
          'x-role': queryArg['x-role'],
          'x-user': queryArg['x-user'],
        },
        params: { scenario_id: queryArg.scenarioId },
      }),
    }),
    getAdvisoryScenariosV1SmosAdvisoryScenarioGet: build.query<
      GetAdvisoryScenariosV1SmosAdvisoryScenarioGetApiResponse,
      GetAdvisoryScenariosV1SmosAdvisoryScenarioGetApiArg
    >({
      query: () => ({ url: `/v1/smos/advisory-scenario` }),
    }),
    pushScenarioV1SmosAdvisoryScenarioPost: build.mutation<
      PushScenarioV1SmosAdvisoryScenarioPostApiResponse,
      PushScenarioV1SmosAdvisoryScenarioPostApiArg
    >({
      query: (queryArg) => ({ url: `/v1/smos/advisory-scenario`, method: 'POST', body: queryArg.advisoryDto }),
    }),
    getAdvisoryScenarioV1SmosAdvisoryScenarioScenarioIdGet: build.query<
      GetAdvisoryScenarioV1SmosAdvisoryScenarioScenarioIdGetApiResponse,
      GetAdvisoryScenarioV1SmosAdvisoryScenarioScenarioIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/v1/smos/advisory-scenario/${queryArg.scenarioId}` }),
    }),
    deleteScenarioV1SmosAdvisoryScenarioScenarioIdDelete: build.mutation<
      DeleteScenarioV1SmosAdvisoryScenarioScenarioIdDeleteApiResponse,
      DeleteScenarioV1SmosAdvisoryScenarioScenarioIdDeleteApiArg
    >({
      query: (queryArg) => ({ url: `/v1/smos/advisory-scenario/${queryArg.scenarioId}`, method: 'DELETE' }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as advisoryApi };
export type RootV1HealthCheckGetApiResponse = /** status 200 Successful Response */ any;
export type RootV1HealthCheckGetApiArg = void;
export type ExecuteSmosCalculationV1SmosExecuteOptimizationPostApiResponse = /** status 200 Successful Response */ any;
export type ExecuteSmosCalculationV1SmosExecuteOptimizationPostApiArg = {
  algorithm: string;
  scenarioId: string;
  numberOfScenarios: number;
  sustainabilityPercentage: number;
  profitPercentage: number;
  indicators: string;
  scenarioTitle?: string;
  scenarioDescription?: string;
  'x-realm'?: string | null;
  'x-group'?: string | null;
  'x-role'?: string | null;
  'x-user'?: string | null;
};
export type CreateCombinationTreeV1SmosCreateCombinationTreeTemplateIdPostApiResponse =
  /** status 200 Successful Response */ any;
export type CreateCombinationTreeV1SmosCreateCombinationTreeTemplateIdPostApiArg = {
  templateId: string;
  'x-realm'?: string | null;
  'x-group'?: string | null;
  'x-role'?: string | null;
  'x-user'?: string | null;
};
export type CalculateLinkedCombinationsV1SmosCalculateLinkedCombinationsPostApiResponse =
  /** status 200 Successful Response */ any;
export type CalculateLinkedCombinationsV1SmosCalculateLinkedCombinationsPostApiArg = {
  scenarioId: string;
  'x-realm'?: string | null;
  'x-group'?: string | null;
  'x-role'?: string | null;
  'x-user'?: string | null;
  body: object[];
};
export type HasCombinationTreeV1SmosHasCombinationTreeTemplateIdGetApiResponse =
  /** status 200 Successful Response */ any;
export type HasCombinationTreeV1SmosHasCombinationTreeTemplateIdGetApiArg = {
  templateId: string;
  'x-realm'?: string | null;
  'x-group'?: string | null;
  'x-role'?: string | null;
  'x-user'?: string | null;
};
export type GetLinkedParametersV1SmosLinkedParametersGetApiResponse = /** status 200 Successful Response */ any;
export type GetLinkedParametersV1SmosLinkedParametersGetApiArg = {
  scenarioId: string;
  'x-realm'?: string | null;
  'x-group'?: string | null;
  'x-role'?: string | null;
  'x-user'?: string | null;
};
export type GetAdvisoryScenariosV1SmosAdvisoryScenarioGetApiResponse =
  /** status 200 Successful Response */ AdvisoryDto[];
export type GetAdvisoryScenariosV1SmosAdvisoryScenarioGetApiArg = void;
export type PushScenarioV1SmosAdvisoryScenarioPostApiResponse = /** status 200 Successful Response */ AdvisoryDto;
export type PushScenarioV1SmosAdvisoryScenarioPostApiArg = {
  advisoryDto: AdvisoryDto;
};
export type GetAdvisoryScenarioV1SmosAdvisoryScenarioScenarioIdGetApiResponse =
  /** status 200 Successful Response */ AdvisoryDto;
export type GetAdvisoryScenarioV1SmosAdvisoryScenarioScenarioIdGetApiArg = {
  scenarioId: string;
};
export type DeleteScenarioV1SmosAdvisoryScenarioScenarioIdDeleteApiResponse = /** status 200 Successful Response */ any;
export type DeleteScenarioV1SmosAdvisoryScenarioScenarioIdDeleteApiArg = {
  scenarioId: string;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type AdvisoryDto = {
  scenario_id?: string | null;
  template_id?: string | null;
  sustainability_points?: string | null;
  sustainability_percentage?: number | null;
  profit_points?: string | null;
  profit_percentage?: number | null;
  score?: string | null;
  indicators?: string | null;
};
export const {
  useRootV1HealthCheckGetQuery,
  useExecuteSmosCalculationV1SmosExecuteOptimizationPostMutation,
  useCreateCombinationTreeV1SmosCreateCombinationTreeTemplateIdPostMutation,
  useCalculateLinkedCombinationsV1SmosCalculateLinkedCombinationsPostMutation,
  useHasCombinationTreeV1SmosHasCombinationTreeTemplateIdGetQuery,
  useGetLinkedParametersV1SmosLinkedParametersGetQuery,
  useGetAdvisoryScenariosV1SmosAdvisoryScenarioGetQuery,
  usePushScenarioV1SmosAdvisoryScenarioPostMutation,
  useGetAdvisoryScenarioV1SmosAdvisoryScenarioScenarioIdGetQuery,
  useDeleteScenarioV1SmosAdvisoryScenarioScenarioIdDeleteMutation,
} = injectedRtkApi;
