import { reportApi as api } from './reportApi';

export const enhancedReportApi = api.enhanceEndpoints({
  addTagTypes: ['Report'],
  endpoints: {
    getReport: {
      providesTags: (result, error, arg) => [{ type: 'Report', id: arg.reportId }],
    },
    getAllReports: {
      providesTags: (result, error, arg) => [{ type: 'Report', id: 'LIST' }],
    },
    getReportTemplateForScenarioTemplate: {
      providesTags: (result, error, arg) => {
        return result?.map((r) => ({ type: 'Report', id: r.id })) ?? [];
      },
    },
    getReportTemplateForScenarioTemplates: {
      providesTags: (result, error, arg) => {
        return result?.map((r) => ({ type: 'Report', id: r.id })) ?? [];
      },
    },
    deleteReport: {
      invalidatesTags: (result, error, arg) => [
        { type: 'Report', id: arg?.reportId },
        { type: 'Report', id: 'LIST' },
      ],
    },
    postReportTemplate: {
      invalidatesTags: (result, error, arg) => {
        console.log(arg);
        return [
          { type: 'Report', id: JSON.parse((arg.body as FormData).get('reportTemplateString'))?.id },
          { type: 'Report', id: 'LIST' },
        ];
      },
    },
  },
});
