import React, { useState } from 'react';
import { InputNumber } from '../input/InputNumber';
import './slider.css';

const Slider = ({ min, max, step, value, onChange }) => {
  const [sliderValue, setSliderValue] = useState(value.toString());

  const handleChange = (e) => {
    setSliderValue(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <div className="w-full flex items-center space-x-4">
      <InputNumber
        value={sliderValue}
        min={min}
        max={max}
        step={5}
        onChange={(e) => handleChange({ target: { value: e.target.value } })}
      />
      <input
        id="steps-range"
        type="range"
        min={min}
        max={max}
        step={step}
        value={sliderValue}
        onChange={handleChange}
        className="w-full h-2 slider-input"
        style={{ '--value': sliderValue, '--min': min, '--max': max }}
      />
    </div>
  );
};

export default Slider;
