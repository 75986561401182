import { useFormContext } from 'react-hook-form';
import { ParameterType } from '../../../api/customizationSpace/types/ParameterType';
import classNames from '../../../utils/classNames';
import React, { Fragment, Suspense, useEffect, useMemo } from 'react';
import useRenderCounter from './../../../hooks/useRenderCounter';
import { useResizeDetector } from 'react-resize-detector';
import { IndexedParameter } from './CustomizationTemplateForm';
import { Option } from '../../../api/scenarioTemplate/scenarioTemplateApi';
import { Spinner } from '../../../components/Elements';
import { useCustomizationTemplateContext } from '../hooks/CustomizationTemplateProvider';
import { evaluateScriptWithScope } from '../utils/evaluateScriptWithScope';
import { Inputs } from './input';
/*import { HiOutlineQuestionMarkCircle } from 'react-icons/hi';*/
import { Tooltip } from 'react-tooltip';
import { Show } from '../../../components/show/Show';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import Select from 'react-select';
import { HiMiniFire } from 'react-icons/hi2';
import { InputNumber } from '../../../components/input/InputNumber';
import { Checkbox } from '../../../components/input/Checkbox';

type CustomizationParameterFormProps = IndexedParameter & {
  onFormChange: () => void;
};

export const CustomizationParameterForm = (props: CustomizationParameterFormProps) => {
  const { alias, parameterName, tooltip, parameterType, unitOfMeasure, hidden, onFormChange } = props;

  const { register, setValue, watch, formState, getValues } = useFormContext();

  const renderCounter = useRenderCounter();
  const { width, ref } = useResizeDetector();

  const { advisory, hotspot, currentHotspot, updateCombinations, combinations } = useCustomizationTemplateContext();
  const parameters: IndexedParameter[] = watch('parameters');
  const minValue = watch(`parameters.${props.index}.value`);
  const maxValue = watch(`parameters.${props.index}.max`);
  const step = watch(`parameters.${props.index}.step`);
  const isStatic = watch(`parameters.${props.index}.static`) ?? true;
  const optionsList = watch(`parameters.${props.index}.options_list`);

  const hide = useMemo(
    () => (hidden ? evaluateScriptWithScope(hidden, getValues().parameters, parameterName) : false),
    [hidden, formState],
  );

  const Input = Inputs[parameterType];

  const categoricalOptions: Option[] | undefined = useMemo(() => {
    if (parameterType == ParameterType[ParameterType.OPTION]) {
      return (props as IndexedParameter).options?.map((op) => ({
        value: op.value,
        label: op.label,
      }));
    }
    return undefined;
  }, [props]);
  useEffect(() => {
    if (advisory && ((minValue && maxValue && step) || optionsList || isStatic)) {
      updateCombinations(parameters, parameterType, parameterName, minValue, maxValue, step, optionsList, isStatic);
    }
  }, [minValue, maxValue, step, isStatic, optionsList, advisory]);

  useEffect(() => {
    if (hidden) {
      setValue(`parameters.${props.index}.static`, hidden);
    }
  }, [hidden]);

  const validateStep = (value) => {
    if (!isStatic) {
      const diff = maxValue - Number(minValue);
      if (value === undefined) {
        return 'The step value must be defined';
      } else if (value <= 0) {
        return 'The step value cannot be 0 or less than 0';
      } else if (value > diff) {
        return 'The step value must be less than the interval width';
      }
    }
    return true;
  };

  const validateMax = (max) => {
    if (!isStatic) {
      if (isNaN(max)) {
        return 'Max value must be defined';
      } else {
        if (max < Number(minValue)) {
          return 'The left endpoint of the interval must be greater than the right';
        }
      }
    }
    return true;
  };

  const validateOptions = (optionsList) => {
    if (!isStatic) {
      if (!optionsList || optionsList.length < 2) {
        return 'Select at least two options';
      }
    }
    return true;
  };

  const tooltipElement = useMemo(
    () => (
      <Fragment>
        <HiOutlineInformationCircle
          id={`${parameterName}-tooltip`}
          className={classNames(
            'z-2 ml-1 h-4 w-4 self-center items-center cursor-pointer focus-visible:outline-none hover:text-blue-600 text-blue-400 rounded-full bg-white',
          )}
        />
        <Tooltip
          anchorSelect={`#${parameterName}-tooltip`}
          content={tooltip as string}
          style={{ backgroundColor: 'white', color: 'rgb(29, 78, 213)' }}
          border="1px solid rgb(29, 78, 213)"
        />
      </Fragment>
    ),
    [parameterName, tooltip],
  );

  return (
    <>
      <div ref={ref} className={classNames(hide ? 'hidden' : 'block', 'bg-white px-4 p-1 xs:p-4')}>
        <div className="grid grid-cols-3 gap-6">
          <Show>
            <Show.When isTrue={width && width > 768}>
              <div className="col-span-1 flex items-center">
                <h3 className="flex text-base font-semibold leading-6 text-gray-900">
                  {alias} {unitOfMeasure ? `[${unitOfMeasure}]` : ''}
                  {hotspot && currentHotspot?.includes(props.parameterName as string) && (
                    <HiMiniFire className="ml-1 mt-1 text-red-500" />
                  )}
                  {tooltip && tooltipElement}
                </h3>
              </div>
            </Show.When>
          </Show>
          <div className={classNames(width && width > 768 ? 'col-span-2' : 'col-span-3', 'mt-2')}>
            {Input && (
              <Suspense fallback={<Spinner size={'h-8 w-8  '} />}>
                <div className={`${advisory ? 'flex items-center w-full' : ''}`}>
                  {(!advisory ||
                    (advisory && parameterType !== ParameterType[ParameterType.OPTION]) ||
                    (advisory && parameterType === ParameterType[ParameterType.OPTION] && isStatic)) && (
                    <Input
                      {...props}
                      name={`parameters.${props.index}.value`}
                      label={
                        width && width < 768 ? (
                          <span className={'flex'}>
                            {alias}
                            {unitOfMeasure ? ' [' + unitOfMeasure + ']' : ''}
                            {tooltip && tooltipElement}
                          </span>
                        ) : (
                          ''
                        )
                      }
                      parameters={parameters}
                      className={advisory ? 'flex-1 mr-2' : undefined}
                      hidden={hide}
                    />
                  )}
                  {advisory && (
                    <Fragment>
                      {!isStatic && (
                        <Fragment>
                          {[ParameterType[ParameterType.NUMERIC], ParameterType[ParameterType.STRING]].includes(
                            parameterType,
                          ) && (
                            <Fragment>
                              <InputNumber
                                {...register(`parameters.${props.index}.max`, {
                                  validate: { validateMax },
                                  valueAsNumber: true,
                                })}
                                label={'Max'}
                                className={`flex-1 ${props.description ? 'mb-5' : ''} mr-2`}
                              />
                              <InputNumber
                                {...register(`parameters.${props.index}.step`, {
                                  validate: { validateStep },
                                  valueAsNumber: true,
                                })}
                                label={'Step'}
                                className={`flex-1 ${props.description ? 'mb-5' : ''} mr-2`}
                              />
                            </Fragment>
                          )}
                          {[ParameterType[ParameterType.OPTION]].includes(parameterType) && (
                            <Select
                              {...register(`parameters.${props.index}.options_list`, {
                                // validate: { validateOptions },
                              })}
                              isMulti
                              defaultValue={optionsList}
                              options={categoricalOptions}
                              className={`basic-multi-select flex-1 mr-2`}
                              classNamePrefix="select"
                              onChange={(e) => {
                                setValue(`parameters.${props.index}.options_list`, e);
                              }}
                            />
                          )}
                        </Fragment>
                      )}
                      {![ParameterType[ParameterType.FORMULA], ParameterType[ParameterType.REMOTE]].includes(
                        parameterType,
                      ) && (
                        <Checkbox
                          {...register(`parameters.${props.index}.static`, { value: isStatic })}
                          label={'Fix'}
                          className={`flex-2 h-10 z-0 ${props.description ? 'mb-5' : ''} mr-2`}
                        />
                      )}
                    </Fragment>
                  )}
                </div>
              </Suspense>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
