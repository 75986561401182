import { HiFolderOpen } from 'react-icons/hi2';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Button, Link, Spinner } from '../../../components/Elements';
import React from 'react';
import { toast } from 'react-hot-toast/headless';
import { ReportTemplate, useDeleteReportMutation, useGetAllReportsQuery } from '../../../api/report/reportApi';

type ListReportProps = {};

function ListReport(props: ListReportProps) {
  const { data, error, isLoading } = useGetAllReportsQuery({});

  const [deleteReportTemplate] = useDeleteReportMutation();

  const handleDelete = async (reportId: string) => {
    const response: any = await deleteReportTemplate({
      reportId,
    });

    if (response.error) {
      // console.log(response.error);
      toast.error('Failed to delete report template');
    } else {
      toast.success('Report template deleted successfully');
    }
  };

  if (isLoading) {
    return <Spinner size={'h-14 w-14'} className="w-12 h-12 self-center m-5" />;
  }

  return (
    <div className="bg-white shadow rounded">
      <ul className="divide-y divide-gray-200">
        {!!data?.length ? (
          Object.values(data).map((report: ReportTemplate | undefined) => {
            if (!report) {
              return null;
            }
            return (
              <li key={report.id}>
                <div className="grid grid-cols-12">
                  <Link to={`/app/reports/${report.id}`} className="block col-span-10 hover:bg-blue-50">
                    <div className="px-4 py-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <p className="truncate text-sm font-medium text-blue-600">{report?.name}</p>
                      </div>
                      <div className="mt-2 sm:flex sm:justify-between">
                        <div className="sm:flex">
                          <p className="flex items-center text-sm text-gray-500">{report.label}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Button
                    variant="inverse"
                    className="flex col-span-2 items-center hover:bg-red-100 border-0 text-red-500 rounded-none"
                    onClick={() => handleDelete(report.id as string)}
                  >
                    <RiDeleteBinLine className="h-6 w-6" />
                  </Button>
                </div>
              </li>
            );
          })
        ) : (
          <div className="text-center">
            <HiFolderOpen className={'mx-auto mt-4 h-12 w-12 text-gray-400'} aria-hidden="true" />
            <h3 className="my-2 text-sm font-semibold text-gray-900">No report Templates</h3>
          </div>
        )}
      </ul>
    </div>
  );
}

export default ListReport;
