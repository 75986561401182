import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { REPORT_API_URL } from '../../config';
import { baseQueryWithReauth } from '../utility/queryUtility';
import storage from '../../utils/storage';

const customBaseQuery = fetchBaseQuery({
  baseUrl: REPORT_API_URL,
  // mode: 'cors',
  prepareHeaders: (headers, { getState }) => {
    const token = storage.getToken();

    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
  responseHandler: (response) => {
    // console.log('1');
    const contentType = response.headers.get('Content-Type');
    // If the content is Excel or any other non-JSON, treat it as a blob
    // console.log('2: contentype:', contentType);
    if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      // console.log('2: blob');
      return response.blob(); // handle the binary response
    }
    // console.log('3: json');
    return response.json(); // fallback for JSON responses
  },
});

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptyReportApi = createApi({
  reducerPath: 'report',
  baseQuery: (args, api, extraOptions) => {
    return baseQueryWithReauth(args, customBaseQuery, api, extraOptions);
  },
  endpoints: () => ({}),
  // endpoints: (builder) => ({
  //   downloadTemplate: builder.query({
  //     query: (reportTemplateId) => ({
  //       url: `/template/${reportTemplateId}`,
  //       method: 'POST',
  //       responseHandler: 'blob', // explicitly tell RTK Query that this is a binary file
  //     }),
  //   }),
  // }),
});
//https://redux-toolkit.js.org/rtk-query/usage/code-generation
// npx @rtk-query/codegen-openapi openapi-report-config.json
