import classNames from '../../../../utils/classNames';
import React from 'react';
import { BsRobot } from 'react-icons/bs';
import { Tooltip } from 'react-tooltip';
import ActionMenu from '../../../../components/ActionMenu/ActionMenu';
import { useCalculationLimits } from '../../hooks/AdvisoryLimitationProvider';

export default function AdvisoryNavBar(props) {
  const { items = [], advisoryItem, disabled, scenarioErrors, assessmentTypeStatus, className } = props;
  const { algorithmType, currentNumberOfCombinations, currentNumberOfBinaryCombinations, numericParameters } =
    useCalculationLimits();

  const noCombinations =
    algorithmType === 'binary'
      ? currentNumberOfBinaryCombinations === 0 || isNaN(currentNumberOfBinaryCombinations)
      : currentNumberOfCombinations === 0 || isNaN(currentNumberOfCombinations);

  const noAssessmentTypes = Object.values(assessmentTypeStatus).every((status) => status === false);

  const disableExecution = scenarioErrors || disabled || noCombinations || noAssessmentTypes;

  const robotButtonClasses = disableExecution
    ? 'text-red-700 bg-red-100 hover:bg-red-200'
    : 'text-blue-700 bg-blue-100 hover:bg-blue-200';

  const tooltipContent = () => {
    // leave the order of the conditions as is
    if (scenarioErrors) {
      return 'Enter all parameters to execute optimization';
    }
    if (disabled) {
      return 'Limit of combinations reached';
    }
    if (numericParameters === 0 && algorithmType === 'binary') {
      return 'No numeric parameters to optimize';
    }
    if (
      (currentNumberOfCombinations === 0 && algorithmType === 'brute') ||
      (currentNumberOfBinaryCombinations === 0 && algorithmType === 'binary')
    ) {
      return 'No scenarios to optimize';
    }
    if (noAssessmentTypes) {
      return 'No LCA and LCC assessment types available';
    }
    return 'Execute optimization';
  };

  return (
    <div className={classNames(className ? className : '', 'bg-white flex')}>
      <div className="flex ml-auto">
        <BsRobot
          className={classNames('h-8 w-8 z-80 mr-2 rounded cursor-pointer p-1', robotButtonClasses)}
          onClick={disableExecution || scenarioErrors ? null : advisoryItem.onClick}
          id={'advisory'}
        />
      </div>

      <Tooltip
        anchorSelect={'#advisory'}
        place={'right'}
        content={tooltipContent}
        className={'z-20'}
        style={{ backgroundColor: 'white', color: 'rgb(29, 78, 213)', padding: '5px' }}
        border="1px solid rgb(29, 78, 213)"
      />

      <ActionMenu className="" items={items} />
    </div>
  );
}
