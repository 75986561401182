import React, { useEffect } from 'react';
import { useGetAllPhaseQuery } from '../../../api/project/projectApi';
import { Link } from '../../../components/Elements';
import { useBreadcrumbs } from '../../../hooks/breadcrumbs';
import ListPhase from '../components/ListPhase';
import { HiPlusCircle } from 'react-icons/hi2';
import { RiDeleteBinLine } from 'react-icons/ri';

export const Phases = () => {
  const { setBreadcrumbs } = useBreadcrumbs();

  const { data: phases } = useGetAllPhaseQuery({});

  useEffect(() => {
    setBreadcrumbs([{ label: 'phases' }]);
  }, []);

  return (
    <div className="flex flex-col mt-3">
      {/*<div className="my-3">
        <Link to="./new">
          <Button className={'float-right'} style={{ height: '46px' }} variant="inverse" size={'md'}>
            <HiPlusCircle className="mr-2 h-6 w-6" /> Create New
          </Button>
        </Link>
      </div>*/}

      <div className="relative grid grid-cols-12 flex border border-gray-400 border-dashed border-spacing-2">
        <Link
          to={'./new'}
          /*className={'absolute flex w-full h-full text-gray-600 cursor-pointer content-center items-center text-center backdrop-blur-[4px] hover:bg-blue-100 hover:bg-opacity-80 hover:text-blue-600 hover:border-blue-400'}>*/
          className={
            'absolute flex w-full h-full text-gray-600 cursor-pointer bg-opacity-60 bg-white content-center items-center text-center hover:bg-blue-100 hover:bg-opacity-90 hover:text-blue-600 hover:border-blue-400'
          }
        >
          <div className="absolute left-[40%]">
            <HiPlusCircle className="h-6 w-6 mr-2 float-left" /> Create New Phase
          </div>
        </Link>

        <div className="block col-span-10 hover:bg-blue-50 p-4 bg-white">
          <div className="flex items-center justify-between">
            <p className="truncate text-sm font-medium text-blue-600">New phase name</p>
          </div>
        </div>
        <div
          className={
            'justify-center flex col-span-2 items-center hover:bg-red-100 border-0 text-red-500 rounded-none bg-white'
          }
        >
          <RiDeleteBinLine className="h-6 w-6" />
        </div>
      </div>

      <ListPhase phases={phases} />
    </div>
  );
};
