import React, { PureComponent } from 'react';
import { Cell, Pie, PieChart } from 'recharts';

const RADIAN = Math.PI / 180;

const needle = (percentage, cx, cy, iR, oR, value, sections) => {
  const color = '#000000';
  const cappedPercentage = Math.min(percentage, 100);
  const ang = 180.0 * (1 - cappedPercentage / 100);
  const length = (iR + 2 * oR) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx + 5;
  const y0 = cy + 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  // Determine the sector color based on the value
  let labelColor = '#ff5050';
  for (let i = 0; i < sections.length; i++) {
    if (percentage <= sections[i].value) {
      labelColor = sections[i].color;
      break;
    }
    percentage -= sections[i].value;
  }

  return [
    <circle key="needle-circle" cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
    <path
      key="needle-path"
      d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
      stroke="none"
      fill={color}
    />,
    <text x={cx} y={cy - 10} text-anchor="middle" fill={labelColor} fontSize={17} fontWeight={'bold'}>
      {value}
    </text>,
  ];
};

const formatLabelValue = (value) => {
  let formattedValue;

  if (value >= 1_000_000_000) {
    formattedValue = `${(value / 1_000_000_000).toFixed(1)}B`; // Billions
  } else if (value >= 1_000_000) {
    formattedValue = `${(value / 1_000_000).toFixed(1)}M`; // Millions
  } else if (value >= 1_000) {
    formattedValue = `${(value / 1_000).toFixed(1)}K`; // Thousands
  } else {
    formattedValue = value.toFixed(0); // Plain value
  }

  // Ensure output doesn't exceed 6 characters (including suffix)
  if (formattedValue.length > 6) {
    return formattedValue.substring(0, 6);
  }

  return formattedValue;
};

export class GaugeChart extends PureComponent {
  render() {
    const { id, value, width, height, innerRadius, outerRadius, title, labelValue } = this.props;

    const cx = width / 2;
    const cy = height - 30;

    const sections = [
      { name: 'Green', value: 70, color: '#6fb46f' }, // 0-70%
      { name: 'Yellow', value: 20, color: '#ffff50' }, // 70-90%
      { name: 'Red', value: 10, color: '#ff5050' }, // 90-100%
    ];
    const needleValue = value ? value : 0;
    const needleLabelValue = labelValue ? formatLabelValue(labelValue) : 0;

    return (
      <div className={'flex flex-col items-center space-x-2'}>
        <PieChart width={width} height={height}>
          <Pie
            id={id}
            dataKey="value"
            startAngle={180}
            endAngle={0}
            data={sections}
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            fill="#8884d8"
            stroke="none"
            isAnimationActive={false}
          >
            {sections.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          {needle(needleValue, cx, cy, innerRadius, outerRadius, needleLabelValue, sections)}
        </PieChart>

        {title && <p className="text-lg font-bold">{title}</p>}
      </div>
    );
  }
}
