import React, { useState } from 'react';
import * as Yup from 'yup';
import { FormProps } from '../../../components/types';
import { RemoteFlow } from '../../../api/remoteDispatcher/remoteDispatcherApi';
import { InputText, SelectControl } from '../../../components/input';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '../../../components/Elements';
import { RiDeleteBinLine, RiEditBoxLine } from 'react-icons/ri';
import { InputList } from '../../../components/input/InputList';
import { Checkbox } from '../../../components/input/Checkbox';
import { ScenarioTemplate } from '../../../api/scenarioTemplate/scenarioTemplateApi';
import { useDownloadTemplateMutation, usePostReportTemplateMutation } from '../../../api/report/reportApi';
import report from './Report';
import { toast } from 'react-hot-toast/headless';

type ReportFormProps = FormProps & {
  scenarioTemplates: ScenarioTemplate[];
};

export const ReportForm = (props: ReportFormProps) => {
  const { onSubmit, onError, scenarioTemplates, initialValues, submitLabel, className } = props;

  const [downloadTemplate] = useDownloadTemplateMutation();
  const [files, setFiles] = useState<FileList | null>();
  const reportSchema = Yup.object().shape({
    name: Yup.string().required('Report name is required').nullable(),
    description: Yup.string().required('Report name is required').nullable(),
    label: Yup.string().required('Button label is required').nullable(),
    id: Yup.string(),
  });

  const { register, control, handleSubmit } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(reportSchema),
  });

  const downloadFile = async () => {
    try {
      const response = await downloadTemplate({ reportTemplateId: initialValues.id });
      console.log(response);
      // Create a blob from the response data
      if ('error' in response) {
        toast.error(response.error.error);
      } else {
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${initialValues.id}-report-template.xlsx`; // File name for download
        link.click(); // Programmatically trigger the download

        // Cleanup URL object
        window.URL.revokeObjectURL(link.href);
      }
    } catch (error) {
      console.error('Error downloading the template file', error);
    }
  };

  const renderFileList = () => (
    <ol className="grid grid-cols-3 bg-blue-300 p-2 mt-2 rounded">
      {[...(files ?? [])].map((f: any, i: number) => (
        <li key={i} className="col-span-1 my-1 m-2 rounded bg-white p-2">
          {f.name}
        </li>
      ))}
    </ol>
  );

  return (
    <form
      className={'shadow rounded bg-white p-4 mt-3'}
      onSubmit={handleSubmit((formValue) => onSubmit(formValue, files), console.error)}
    >
      <div className="flex flex-col">
        <div className="flex-1">
          <div className="h-44 w-full relative border-2 rounded border-blue-200 border-dashed">
            <div className={'absolute h-36 w-full'}>
              <div className="flex flex-col items-center justify-center pb-6 pt-5">
                <svg
                  className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">Click to upload</span> or drag and drop
                </p>
                {initialValues?.id && (
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    If a new template is uploaded it will override the existing one.
                  </p>
                )}
                <p className="text-xs text-gray-500 dark:text-gray-400">Xlsx files (MAX. 20Mb)</p>
              </div>
            </div>
            <input
              className="absolute h-36 w-full z-10 bg-transparent text-transparent"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              type="file"
              onChange={(e) => {
                console.log(e.target.files);
                setFiles(e.target.files);
              }}
            />
          </div>
        </div>

        {files ? <div>{renderFileList()}</div> : null}
        <InputText className={'my-3'} {...register('name')} label="Report Name" />
        <InputText className={'my-3'} {...register('label')} label="Button label" />
        <InputText className={'my-3'} {...register('description')} label="Button description" />
        <InputText className={'my-3'} {...register('id')} hidden={true} />

        {initialValues?.id && (
          <Button className="flex-1 items-center" onClick={downloadFile}>
            Download existing report template
          </Button>
        )}
        <InputList
          control={control}
          name={'reportTemplateScenarioTemplate'}
          className={'my-3'}
          label={'Associated scenario templates'}
          item={{ scenarioTemplateId: '' }}
          renderItem={(field, index, remove, append) => {
            return (
              <div className={'col-span-12 mt-4 flex'}>
                <SelectControl
                  control={control}
                  className={'flex-1'}
                  label={'Scenario template'}
                  name={`reportTemplateScenarioTemplate.${index}.scenarioTemplateId`}
                  options={scenarioTemplates?.map((st) => ({ value: st.id, label: st.name }))}
                />

                <RiDeleteBinLine className="h-full w-6 text-red-500 self-center mx-2" onClick={() => remove(index)} />
              </div>
            );
          }}
        />
      </div>
      <Button variant={'inverse'} type="submit" className={'float-right'}>
        <RiEditBoxLine className="h-6 w-6 mr-2" /> {submitLabel ? submitLabel : 'Create'}
      </Button>
    </form>
  );
};
