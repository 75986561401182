import { Route, Routes } from 'react-router-dom';
import React from 'react';
import { Permissions } from './Permissions';
import Permission from '../components/Permission';
import CreatePermission from '../components/CreatePermission';

export const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Permissions />} />
      <Route path=":role" element={<Permission />} />
      <Route path="/edit/:role" element={<Permission />} />
      <Route path="/new" element={<CreatePermission />} />
    </Routes>
  );
};
