import {
  HiBars3BottomLeft,
  HiMiniCubeTransparent,
  HiOutlineArrowPathRoundedSquare,
  HiOutlineBolt,
  HiOutlineSquare3Stack3D,
  HiOutlineSquares2X2,
  HiOutlineTag,
} from 'react-icons/hi2';

import './style/style.css';
import { RiCheckboxMultipleBlankLine, RiFoldersLine } from 'react-icons/ri';
import { GrCompare } from 'react-icons/gr';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../logo.png';
import classNames from '../../utils/classNames';
import Breadcrumbs from './Breadcrumb/Breadcrumbs';
import UserNavigation from './UserNavigation';
import useRenderCounter from '../../hooks/useRenderCounter';
import { useAuth } from '../../features/auth/AuthProvider';
import { TbCubeUnfolded } from 'react-icons/tb';
import { AiOutlineApi } from 'react-icons/ai';
import { PiFlowArrow, PiTreeViewLight } from 'react-icons/pi';
import storage from '../../utils/storage';
import { useResizeDetector } from 'react-resize-detector';
import { BsFiletypeXls } from 'react-icons/bs';

type NavigationMenu = {
  name: string;
  children: NavigationItem[];
};

type NavigationItem = {
  name: string;
  shortName: string;
  to: string;
  end?: boolean;
  icon: React.ElementType;
  children?: NavigationItem[];
};

const CustomImage = (props) => {
  return (
    <img
      src={`/cubes.svg`}
      alt={'outlined cubes'}
      className={classNames(props.className ? props.className : '')}
      style={{ minWidth: '1.6rem', height: '1.6rem' }}
    />
  );
};

export const MainLayout = (props: any) => {
  /*const [sidebarOpen, setSidebarOpen] = useState(false);*/
  const [showSidebarText, setShowSidebarText] = useState(true);
  const [lastWidth, setLastWidth] = useState(0);
  const renderCounter = useRenderCounter();
  const { user, changeDefaultRealm, setActualRealm, actualRealm, defaultRealm, allowedRealms, login } = useAuth();

  const navigationItems: NavigationMenu[] = [
    {
      name: 'Platform',
      children: [
        {
          name: 'Projects',
          shortName: 'Projects',
          to: 'app/projects',
          icon: RiFoldersLine,
        },
        {
          name: 'Scenarios',
          shortName: 'Scenarios',
          to: 'app/scenarios',
          icon: RiCheckboxMultipleBlankLine,
        },
        {
          name: 'Comparison',
          shortName: 'Compare',
          to: 'app/comparisons',
          icon: GrCompare,
        },
      ],
    },
    {
      name: 'Expert',
      children: [
        {
          name: 'Templates',
          shortName: 'Templates',
          end: true,
          to: 'app/templates',
          icon: TbCubeUnfolded,
          children: [
            {
              name: 'Basic',
              shortName: 'Basic',
              to: 'app/templates/basic',
              icon: HiMiniCubeTransparent,
            },
            {
              name: 'BoM',
              shortName: 'BoM',
              to: 'app/templates/bom',
              icon: CustomImage,
            },
          ],
        },
        {
          name: 'Modeled Processes',
          shortName: 'Models',
          to: 'app/modeled-processes',
          icon: HiOutlineSquares2X2,
        },
        {
          name: 'Phases',
          shortName: 'Phases',
          to: 'app/phases',
          icon: HiOutlineArrowPathRoundedSquare,
        },
        {
          name: 'Tags',
          shortName: 'Tags',
          to: 'app/tags',
          icon: HiOutlineTag,
        },
        {
          name: 'Customization Spaces',
          shortName: 'Spaces',
          to: 'app/customization-spaces',
          icon: HiOutlineSquare3Stack3D,
        },
        {
          name: 'Methodologies',
          shortName: 'Methods',
          to: 'app/impact-methods',
          icon: HiOutlineBolt,
        },
        {
          name: 'External Connections',
          shortName: 'Connect',
          to: 'app/integrations',
          icon: AiOutlineApi,
        },
        {
          name: 'Remote Flows',
          shortName: 'Flows',
          to: 'app/remote-flows',
          icon: PiFlowArrow,
        },
        {
          name: 'BoM Trees',
          shortName: 'BoM Trees',
          to: 'app/bill-of-materials',
          icon: PiTreeViewLight,
        },
        {
          name: 'Reports',
          shortName: 'Reports',
          to: 'app/reports',
          icon: BsFiletypeXls,
        },
      ],
    },
  ].filter((item) => {
    if (item.name === 'Expert') {
      return user?.role === 'expert' || user?.role === 'admin'; // only include if checkAccess returns true
    }
    return true; // include all other items
  });

  const navigationitemRender = (item: NavigationItem, showSidebarText: boolean) => (
    <div key={item.name} className={'mt-2'}>
      {(showSidebarText || (!showSidebarText && !item.children)) && (
        <NavLink
          to={item.to && item.to}
          end={item.end}
          className={({ isActive, isPending, isTransitioning }) =>
            classNames(
              'hover:bg-blue-200 group flex items-center rounded text-sm font-medium',
              showSidebarText ? 'py-2 px-4' : 'px-8 justify-center h-[3.8rem]',
              isActive ? 'bg-blue-100' : 'text-gray-600',
              /*showSidebarText ? 'w-[248px]' : 'w-[48px]',*/
            )
          }
        >
          <item.icon
            className={classNames('text-gray-600 w-6 flex-shrink-0', showSidebarText ? 'h-[1.4rem] ' : 'h-[2rem] ')}
          />
          {showSidebarText && <span className="truncate pl-3">{item.name}</span>}
          {!showSidebarText && (
            <div className="fixed mt-10 truncate w-[4rem] text-center" style={{ fontSize: '0.7rem' }}>
              {item.shortName}
            </div>
          )}
        </NavLink>
      )}
      {item.children ? (
        <ul className={classNames(showSidebarText ? 'ml-6' : '')}>
          {item.children.map((c: NavigationItem) => navigationitemRender(c, showSidebarText))}
        </ul>
      ) : null}
    </div>
  );

  const handleShowSidebarText = (showSidebarText: boolean) => {
    setShowSidebarText(showSidebarText);
    storage.setSidebarTextStatus(showSidebarText + '');
    //console.log('handleShowSidebarText --> storage ' + storage.getSidebarTextStatus());
    setLastWidth(0);
  };

  const { width, ref } = useResizeDetector();
  useEffect(() => {
    if (width && lastWidth == 0) {
      setLastWidth(width);
      //console.log("lastWidth = "+lastWidth);
    }

    if (storage.getSidebarTextStatus() != null) {
      //console.log('storage ' + storage.getSidebarTextStatus());
      setShowSidebarText(storage.getSidebarTextStatus() === 'true');
    } else {
      handleShowSidebarText(true);
    }

    if (width && width <= 1280 && width < lastWidth) {
      //console.log('width', width);
      setShowSidebarText(false);
      storage.setSidebarTextStatus(showSidebarText + '');
      //console.log(width, lastWidth, storage.getSidebarTextStatus());
      setLastWidth(width);
    } else if (width && width > 1280) {
      setLastWidth(1280);
    }
  }, [width]);

  return (
    <div className="flex flex-col justify-around" ref={ref}>
      <div className="fixed top-0 bg-white z-40 flex-shrink mx-auto pl-[1.5rem] pr-1 lg:divide-y lg:divide-gray-200 w-full py-1">
        {/* navigation bar */}
        <div className="relative flex h-16 justify-between">
          <div className="relative z-10 flex lg:px-0">
            <div className="flex flex-shrink-0 items-center">
              <button
                type="button"
                className="text-blue-700 focus:outline-none block"
                onClick={() => {
                  //setSidebarOpen(true);
                  handleShowSidebarText(!showSidebarText);
                }}
              >
                <span className="sr-only">Open sidebar</span>
                <HiBars3BottomLeft className="h-8 w-8 bg-blue-100 hover:bg-blue-200 p-1 rounded" aria-hidden="true" />
              </button>
              {/*              <button
                type="button"
                className="pr-4 text-blue-700 focus:outline-none block lg:hidden"
                onClick={() => {
                  //setSidebarOpen(true);
                  setShowSidebarText(true);
                }}
              >
                <span className="sr-only">Open sidebar</span>
                <HiBars3BottomLeft className="h-8 w-8 bg-blue-100 hover:bg-blue-200 p-1 rounded" aria-hidden="true" />
              </button>*/}
              <div className={'relative w-64 h-10 ml-6'}>
                <div className="absolute bottom-0 left-3">
                  <img className="block h-10 w-auto" src={logo} alt="GRETA" />
                </div>
                {/*<div
                  className="absolute bottom-2 left-[3.25rem]"
                  style={{
                    fontSize: '21px',
                    paddingLeft: '5px',
                    color: 'rgb(74, 133, 118)',
                    fontFamily: 'Montserrat',
                    letterSpacing: '0.3rem',
                  }}
                >
                  GRETA
                </div>
                <div
                  className="absolute bottom-0 left-[3.25rem]"
                  style={{
                    fontSize: '12px',
                    paddingLeft: '6px',
                    color: 'gray',
                    fontFamily: 'Montserrat',
                  }}
                >
                  GREen TArgets
                </div>*/}
              </div>
            </div>
          </div>
          <div className="relative z-0 flex flex-1 items-center justify-center px-2 sm:absolute sm:inset-0"></div>
          {actualRealm && user && (
            <UserNavigation
              user={user}
              changeDefaultRealm={changeDefaultRealm}
              setActualRealm={setActualRealm}
              defaultRealm={defaultRealm}
              actualRealm={actualRealm}
              allowedRealms={allowedRealms}
              login={login}
              zoom={storage.getZoom() ? storage.getZoom() : 0.8}
            />
          )}
        </div>
      </div>

      <main className="main-content flex-1">
        <div className="grid min-h-[inherit] h-full grid-cols-48">
          {/* Sidebar */}
          {/*<Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 right-0" />
                    </Transition.Child>
                    <div className="mt-5 h-0 flex-1 overflow-y-auto">
                      <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0">
                        <nav className="space-y-1">
                          {navigationItems.map((menu: NavigationMenu) => (
                            <div key={menu.name} className="border-b border-blue-100 py-6">
                              <p className="font-bold text-md">{menu.name}</p>
                              {menu.children.map((item: NavigationItem) => navigationitemRender(item, true))}
                            </div>
                          ))}
                        </nav>
                      </aside>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
                <div className="w-14 flex-shrink-0" aria-hidden="true">
                   Dummy element to force sidebar to shrink to fit close icon
                </div>
              </div>
            </Dialog>
          </Transition.Root>*/}

          <aside
            className={classNames(
              showSidebarText ? 'mt-[3.5rem] w-[15.5rem] px-4' : 'mt-[2.7rem] w-[4rem] ml-2 mr-2',
              'h-full bg-white fixed left-0 py-2 block z-0',
            )}
          >
            <nav className="space-y-1">
              {navigationItems.map((menu: NavigationMenu) => (
                <div key={menu.name} className={classNames(showSidebarText ? '' : '', 'border-b border-blue-100 py-3')}>
                  {showSidebarText && <p className="font-bold text-md">{menu.name}</p>}
                  {menu.children.map((item: NavigationItem) => navigationitemRender(item, showSidebarText))}
                </div>
              ))}
            </nav>
          </aside>

          {/* Main Content */}
          <div
            className={classNames(
              /*showSidebarText ? 'lg:ml-[248px]' : 'lg:ml-[64px]',*/
              showSidebarText ? 'ml-[15.5rem]' : 'ml-[5rem]',
              'mt-[4.5rem] col-span-48 p-3 bg-[#eef2f6] rounded flex flex-col relative mr-2 z-0',
            )}
          >
            {renderCounter}
            <Breadcrumbs />
            {props.children}
          </div>
        </div>
      </main>
    </div>
  );
};
