import { ReportForm } from './ReportForm';
import { useNavigate } from 'react-router-dom';
import {
  IntegrationTemplate,
  PayloadType,
  RemoteFlow,
  useGetAllPayloadTypesQuery,
  useGetAllRemoteFlowsQuery,
  usePostIntegrationTemplateMutation,
} from '../../../api/remoteDispatcher/remoteDispatcherApi';
import { toast } from 'react-hot-toast/headless';
import { Spinner } from '../../../components/Elements';
import { ScenarioTemplate, useGetAllScenarioTemplateQuery } from '../../../api/scenarioTemplate/scenarioTemplateApi';
import { ReportTemplate, usePostReportTemplateMutation } from '../../../api/report/reportApi';

function CreateReport() {
  const navigate = useNavigate();
  const [postReportTemplate] = usePostReportTemplateMutation();
  const { data: scenarioTemplates, isLoading: areScenarioTemplatesLoading } = useGetAllScenarioTemplateQuery({});

  const onSubmit = async (reportTemplate: ReportTemplate, files: FileList | undefined) => {
    const formData = new FormData();
    console.log(reportTemplate, files);
    if (files) {
      formData.append(`reportTemplateFile`, files[0] as File);
    }
    formData.append(`reportTemplateString`, JSON.stringify(reportTemplate));
    console.log(formData);
    const updatedReport: any = await postReportTemplate({
      body: formData,
    }).unwrap();
    if (updatedReport.error) {
      // console.log(updatedScenario.error);
      toast.error('Failed to update scenario');
    } else {
      toast.success('Scenario updated successfully');
    }
  };

  if (areScenarioTemplatesLoading) {
    return <Spinner size={'h-14 w-14'} className="w-12 h-12 self-center m-5" />;
  }

  return (
    <ReportForm
      className={'mt-2'}
      onSubmit={onSubmit}
      formMode={'CREATE'}
      scenarioTemplates={scenarioTemplates as ScenarioTemplate[]}
    />
  );
}

export default CreateReport;
