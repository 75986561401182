import {
  useCopyScenarioCustomizedProcessMutation,
  useDeleteCustomizedProcessByScenarioIdMutation,
} from '../../../api/customizedScenario/customizedScenarioApi';
import { RiDeleteBinLine, RiEditBoxLine } from 'react-icons/ri';
import { GrDuplicate } from 'react-icons/gr';
import {
  ScenarioDto,
  Tag,
  useDeleteScenarioMutation,
  useGetAllProjectQuery,
  usePostScenarioMutation,
} from '../../../api/project/projectApi';
import {
  AssessmentConfiguration,
  ScenarioTemplate,
  useGetAllScenarioTemplateQuery,
} from '../../../api/scenarioTemplate/scenarioTemplateApi';
import { Button, Link, Spinner } from '../../../components/Elements';
import PaginationTable from '../../../components/Pagination/PaginationTable';
import classNames from '../../../utils/classNames';
import { toast } from 'react-hot-toast/headless';
import { TbSquareLetterC, TbSquareLetterE, TbSquareLetterR, TbSquareLetterS } from 'react-icons/tb';
import { LiaCubeSolid, LiaCubesSolid, LiaRobotSolid } from 'react-icons/lia';
import React, { useState } from 'react';
import ButModal from '../../../components/Modal/ButModal';
import { Tooltip } from 'react-tooltip';
import {
  useDeleteScenarioV1SmosAdvisoryScenarioScenarioIdDeleteMutation,
  useGetAdvisoryScenariosV1SmosAdvisoryScenarioGetQuery,
} from '../../../api/advisory/advisoryApi';
import scenario from './Scenario';
import { useAuth } from '../../auth/AuthProvider';
import { Roled } from '../../roles/hooks/Roled';

const assessmentTypesMap = {
  LCA: <TbSquareLetterE id={'LCA'} className="h-6 w-6 text-green-600" />,
  LCC: <TbSquareLetterC id={'LCC'} className="h-6 w-6 text-blue-600" />,
  SLCA: <TbSquareLetterS id={'SLCA'} className="h-6 w-6 text-pink-600" />,
  CE: <TbSquareLetterR id={'CE'} className="h-6 w-6" style={{ color: '#964B00' }} />,
};

function ListScenario(props: any) {
  const { filter, fetchData, className } = props;

  const { user } = useAuth();
  const [deleteScenario] = useDeleteScenarioMutation();
  const [deleteAdvisoryScenario] = useDeleteScenarioV1SmosAdvisoryScenarioScenarioIdDeleteMutation();
  const [deleteCustomizedProcess] = useDeleteCustomizedProcessByScenarioIdMutation();
  const [postScenario] = usePostScenarioMutation();
  const [copyScenarioCustomizedProcess] = useCopyScenarioCustomizedProcessMutation();
  const [selectedScenarioToDelete, setSelectedScenarioToDelete] = useState<ScenarioDto | null>(null);

  const { data: projects, isLoading: isLoadingProject } = useGetAllProjectQuery({});

  const { data: scenarioTemplates, isLoading: isLoadingScenarioTemplates } = useGetAllScenarioTemplateQuery({});

  const { data: advisoryScenarios, isLoading: isLoadingAdvisoryScenarios } =
    useGetAdvisoryScenariosV1SmosAdvisoryScenarioGetQuery({});

  const duplicateScenario = async (scenario: ScenarioDto) => {
    if (!scenario.aiBased) {
      const newScenario: ScenarioDto = await postScenario({
        scenarioDto: {
          name: `${scenario.name} - copy`,
          projectId: scenario.projectId,
          scenarioTemplateId: scenario.scenarioTemplateId,
          description: scenario.description,
        },
      }).unwrap();

      const scenarioCopy: any = await copyScenarioCustomizedProcess({
        oldScenarioId: scenario.id as string,
        newScenarioId: newScenario.id as string,
      });

      if (scenarioCopy.error) {
        toast.error('Failed to duplicate scenario');
      } else {
        toast.success('Scenario duplicated successfully');
      }
    }
  };
  const deleteScenarioConfirmed = async (id: string) => {
    const response: any = await deleteScenario({ scenarioId: id as string });
    if (scenario.aiBased) {
      await deleteAdvisoryScenario({ scenarioId: id as string });
    }
    if (response.error) {
      toast.error('Failed to delete scenario');
    } else {
      toast.success('Scenario deleted successfully');
    }
  };

  function formatRank(score) {
    return score.toFixed(2);
  }

  function formatScore(sustainabilityPoints: string | undefined, profitPoints: string | undefined) {
    return parseFloat((Number(sustainabilityPoints) + Number(profitPoints)).toFixed(4));
  }

  const renderItem = (scenario: ScenarioDto, additionalParameters: any) => {
    const { projects, scenarioTemplates, advisoryScenarios } = additionalParameters;

    const scenarioTemplate = scenarioTemplates?.find((st: ScenarioTemplate) => st.id === scenario.scenarioTemplateId);

    const advisoryData = advisoryScenarios?.find((as: any) => as.scenario_id === scenario.id);

    return (
      <li key={scenario.id}>
        <div className="grid grid-cols-12">
          <Tooltip
            className={'bg-red-500'}
            anchorSelect={`#template-${scenarioTemplate.id}`}
            content={`${scenarioTemplate.name}`}
          />
          <Link
            to={`/app/customizations/${scenario.id}${filter.projectId ? '/project/' + filter.projectId : ''}`}
            className="block col-span-9 hover:bg-blue-100 px-4 py-3.5 focus:outline-none"
          >
            <div className="grid grid-cols-12">
              <div className="col-span-1">
                {scenarioTemplate?.type === 'BOM' ? (
                  <>
                    <LiaCubesSolid id={`template-${scenarioTemplate.id}`} className="h-6 w-6" />
                  </>
                ) : (
                  <>
                    <LiaCubeSolid id={`template-${scenarioTemplate.id}`} className="h-6 w-6" />
                  </>
                )}
                {scenario.aiBased && (
                  <>
                    <LiaRobotSolid id={`robot-${scenarioTemplate.id}`} className="h-6 w-6" />
                    <Tooltip
                      className="bg-red-500"
                      anchorSelect={`#robot-${scenarioTemplate.id}`}
                      content="Generated by AI"
                    />
                  </>
                )}
              </div>

              <p className="col-span-7 truncate text-sm font-medium text-blue-600">
                {scenario.aiBased && advisoryData
                  ? `[${formatRank(Number(advisoryData?.score))}] ${scenario.name}`
                  : scenario.name}
                {scenario.tags?.map((tag: Tag) => {
                  return (
                    <div
                      key={tag?.id}
                      className="inline-flex rounded px-2 text-xs font-semibold leading-5 text-white ml-3"
                      style={{ backgroundColor: tag.color }}
                    >
                      {tag.name}
                    </div>
                  );
                })}
              </p>
              <div className="col-span-2 flex py-1">
                {scenarioTemplates
                  ?.find((st: ScenarioTemplate) => st.id === scenario.scenarioTemplateId)
                  ?.assessmentConfigurations?.map((ac: AssessmentConfiguration) => {
                    return (
                      <>
                        <Tooltip
                          anchorSelect={`#${ac.assessmentType}`}
                          content={ac.assessmentType}
                          style={{ backgroundColor: 'white', color: 'rgb(29, 78, 213)' }}
                          border="1px solid rgb(29, 78, 213)"
                        />
                        {assessmentTypesMap[ac.assessmentType as string]}
                      </>
                    );
                  })}
              </div>
              <p className="col-span-2 m-1 text-center content-center rounded bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                {projects?.content?.find((p) => p.id === scenario.projectId)?.name}
              </p>
              {/*<p className="col-span-1 m-1 rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">*/}
              {/*  {scenarioTemplates?.find((st) => st.id === scenario.scenarioTemplateId)?.name}*/}
              {/*</p>*/}
            </div>
            <div className="grid grid-cols-12">
              <div className="col-start-2 col-span-9 sm:justify-between">
                <p className="whitespace-wrap flex items-center text-sm text-gray-500 pr-2">
                  {scenario.aiBased && advisoryData ? (
                    <>
                      {scenario.description} (it has been
                      {advisoryData?.indicators ? (
                        <> optimized for impact indicators [{advisoryData.indicators}] and generated with </>
                      ) : (
                        <> generated with </>
                      )}
                      sustainability percentage = {advisoryData.sustainability_percentage}%, profit percentage ={' '}
                      {advisoryData.profit_percentage}%, the final score is{' '}
                      {formatScore(advisoryData.sustainability_points, advisoryData.profit_points)})
                    </>
                  ) : (
                    scenario.description
                  )}
                </p>
              </div>
              <div className="mt-2 col-span-2 text-center content-center text-sm text-gray-500 sm:mt-0">
                <p>{new Date(scenario.created as string).toDateString()}</p>
              </div>
            </div>
          </Link>
          {/* <Link className="flex col-span-1 items-center hover:bg-green-100" to={`../assessments/${scenario.id}`}>
                    <ChartBarSquareIcon className="h-6 w-6 m-auto text-green-600" />
                  </Link> */}

          <Roled name={'edit-scenario'}>
            <Link
              to={!scenario.aiBased ? `/app/scenarios/edit/${scenario.id}` : ''}
              className="edit-scenario flex col-span-1 items-center hover:bg-blue-100 text-center justify-center text-blue-600 disabled:bg-white"
            >
              <Tooltip
                anchorSelect={'#edit'}
                content="Edit"
                style={{ outline: 'none', backgroundColor: 'white', color: 'rgb(29, 78, 213)' }}
                border="1px solid rgb(29, 78, 213)"
              />
              <RiEditBoxLine id={'edit'} className="h-6 w-6 m-auto focus:outline-none"></RiEditBoxLine>
            </Link>
          </Roled>

          <Roled name={'duplicate-scenario'}>
            <Button
              variant="none"
              className="w-full flex col-span-1 items-center hover:bg-blue-100 border-0 rounded-none text-blue-600 disabled:bg-white"
              onClick={() => duplicateScenario(scenario)}
            >
              <Tooltip
                anchorSelect={'#duplicate'}
                content="Duplicate"
                style={{ backgroundColor: 'white', color: 'rgb(29, 78, 213)' }}
                border="1px solid rgb(29, 78, 213)"
              />
              <GrDuplicate id={'duplicate'} className="h-5 w-5 focus:outline-none" />
            </Button>
          </Roled>

          <Roled name={'delete-scenario'}>
            <ButModal
              label={<RiDeleteBinLine id={'delete'} className="h-6 w-6" />}
              submitLabel={'Yes'}
              confirm={() => {
                deleteScenarioConfirmed(scenario.id as string).then((r) => {
                  deleteCustomizedProcess({ scenarioId: scenario.id as string });
                  if (scenario.aiBased) {
                    deleteAdvisoryScenario({ scenarioId: scenario.id as string });
                  }
                });
              }}
              className={
                'flex col-span-1 items-center hover:bg-red-100 border-0 text-red-500 rounded-none disabled:bg-white'
              }
            >
              <Tooltip anchorSelect={'#delete'} content="Delete" />
              <h2 className="text-xl text-gray-700 mb-4 mt-1 px-4 text-center focus:outline-none">
                Are you sure you want to delete the "{scenario?.name}" scenario?
              </h2>
            </ButModal>
          </Roled>
        </div>
      </li>
    );
  };

  if (isLoadingProject || isLoadingScenarioTemplates || isLoadingAdvisoryScenarios) {
    return <Spinner size={'h-14 w-14'} className="w-12 h-12 self-center m-5" />;
  }

  return (
    <div className={classNames(className ? className : '', 'bg-white shadow sm:rounded')}>
      <ul className="divide-y divide-gray-200">
        <PaginationTable
          args={filter}
          noDataMessage={'No scenarios'}
          additionalParameters={{
            projects: projects,
            scenarioTemplates: scenarioTemplates,
            advisoryScenarios: advisoryScenarios,
          }}
          fetchData={fetchData}
          renderItem={renderItem}
        />
      </ul>
    </div>
  );
}

export default ListScenario;
