import { emptyReportApi as api } from './emptyApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    downloadTemplate: build.mutation<DownloadTemplateApiResponse, DownloadTemplateApiArg>({
      query: (queryArg) => ({
        url: `/v1/report/template/${queryArg.reportTemplateId}`,
        method: 'POST',
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    postReportTemplate: build.mutation<PostReportTemplateApiResponse, PostReportTemplateApiArg>({
      query: (queryArg) => ({
        url: `/v1/report/post-report-template`,
        method: 'POST',
        body: queryArg.body,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    excelGeneration: build.mutation<ExcelGenerationApiResponse, ExcelGenerationApiArg>({
      query: (queryArg) => ({
        url: `/v1/report/generate/${queryArg.scenarioId}/${queryArg.reportTemplateId}`,
        method: 'POST',
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getReport: build.query<GetReportApiResponse, GetReportApiArg>({
      query: (queryArg) => ({
        url: `/v1/report/${queryArg.reportId}`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    deleteReport: build.mutation<DeleteReportApiResponse, DeleteReportApiArg>({
      query: (queryArg) => ({
        url: `/v1/report/${queryArg.reportId}`,
        method: 'DELETE',
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getReportTemplateForScenarioTemplates: build.query<
      GetReportTemplateForScenarioTemplatesApiResponse,
      GetReportTemplateForScenarioTemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/report/report-template`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
        params: { scenarioTemplateIds: queryArg.scenarioTemplateIds },
      }),
    }),
    getReportTemplateForScenarioTemplate: build.query<
      GetReportTemplateForScenarioTemplateApiResponse,
      GetReportTemplateForScenarioTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/report/report-template/${queryArg.scenarioTemplateId}`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getAllReports: build.query<GetAllReportsApiResponse, GetAllReportsApiArg>({
      query: (queryArg) => ({
        url: `/v1/report/`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as reportApi };
export type DownloadTemplateApiResponse = unknown;
export type DownloadTemplateApiArg = {
  reportTemplateId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type PostReportTemplateApiResponse = /** status 200 OK */ object;
export type PostReportTemplateApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  body: {
    reportTemplateFile: Blob;
    reportTemplateString: string;
  };
};
export type ExcelGenerationApiResponse = unknown;
export type ExcelGenerationApiArg = {
  scenarioId: string;
  reportTemplateId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type GetReportApiResponse = /** status 200 OK */ ReportTemplate;
export type GetReportApiArg = {
  reportId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type DeleteReportApiResponse = /** status 200 OK */ object;
export type DeleteReportApiArg = {
  reportId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type GetReportTemplateForScenarioTemplatesApiResponse = /** status 200 OK */ ReportTemplate[];
export type GetReportTemplateForScenarioTemplatesApiArg = {
  scenarioTemplateIds: string[];
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type GetReportTemplateForScenarioTemplateApiResponse = /** status 200 OK */ ReportTemplate[];
export type GetReportTemplateForScenarioTemplateApiArg = {
  scenarioTemplateId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type GetAllReportsApiResponse = /** status 200 OK */ ReportTemplate[];
export type GetAllReportsApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type ReportTemplateScenarioTemplate = {
  id?: string;
  reportTemplate?: ReportTemplate;
  scenarioTemplateId?: string;
};
export type ReportTemplate = {
  created?: string;
  lastUpdated?: string;
  createdBy?: string;
  realm?: string;
  id?: string;
  name?: string;
  label?: string;
  description?: string;
  reportTemplateScenarioTemplate?: ReportTemplateScenarioTemplate[];
};
export const {
  useDownloadTemplateMutation,
  usePostReportTemplateMutation,
  useExcelGenerationMutation,
  useGetReportQuery,
  useDeleteReportMutation,
  useGetReportTemplateForScenarioTemplatesQuery,
  useGetReportTemplateForScenarioTemplateQuery,
  useGetAllReportsQuery,
} = injectedRtkApi;
