import React, { Fragment, useMemo } from 'react';
import classNames from '../../../utils/classNames';
import { useRoleContext } from './RoleContext';
import { Properties, Property } from '../../../api/roleBasedAccessManager/roleBasedAccessManager';

type RoledProps = {
  disabled?: boolean;
  children?: React.ReactNode;
  name: string;
};

export const Roled = ({ children, disabled, name, ...props }: RoledProps) => {
  const { getProps } = useRoleContext();

  const roleProp: Properties = getProps(name);

  const memoizedChildren = useMemo(() => {
    return React.Children.map(children, (child) => {
      // If no roleProp is provided, return child as is

      console.log(name, ' cloning');

      if (!roleProp || !React.isValidElement(child)) {
        return child;
      }

      // If visibility is false, return null to hide the child
      if (roleProp.visible === false) {
        return <Fragment />;
      }

      // Apply className and disabled properties without unnecessary re-render
      return React.cloneElement(child, {
        disabled: roleProp.disabled,
        className: classNames(child.props.className, roleProp.className),
      });
    });
  }, [children, roleProp]); // Only recalculate when children or roleProp change

  return <Fragment>{memoizedChildren}</Fragment>;
};
