import React, { Fragment, useMemo, useState } from 'react';
import { useExecuteSmosCalculationV1SmosExecuteOptimizationPostMutation } from '../../../../api/advisory/advisoryApi';
import { useNavigate } from 'react-router';
import { useCustomizationTemplateContext } from '../../hooks/CustomizationTemplateProvider';
import { InputText } from '../../../../components/input';
import { InputNumber } from '../../../../components/input/InputNumber';
import { useCalculationLimits } from '../../hooks/AdvisoryLimitationProvider';
import { useGetImpactMethodByIdQuery } from '../../../../api/lightweightOlcaCore/lightweightOlcaCoreApi';
import { skipToken } from '@reduxjs/toolkit/query';
import { GaugeChart } from '../../../../components/GaugeChart/GaugeChart';
import { SimpleSelect } from '../../../../components/input/SimpleSelect';
import AdvisoryNavBar from './AdvisoryNavBar';
import { HiOutlineArrowDownOnSquare } from 'react-icons/hi2';
import { MenuItem } from '../../../../components/ActionMenu/ActionMenu';

export const AdvisoryPopup = (props) => {
  const { scenarioTemplate, scenarioErrors, handleSaveCustomization } = props;

  const [postAdvisoryRequest] = useExecuteSmosCalculationV1SmosExecuteOptimizationPostMutation();
  const { scenarioId, projectId } = useCustomizationTemplateContext();
  const {
    algorithmType,
    setAlgorithmType,
    currentNumberOfCombinations,
    currentNumberOfBinaryCombinations,
    maxCombinationsBrute,
    maxCombinationsBinary,
    executionTimeBinary,
    executionTimeBrute,
    isWithinLimit,
  } = useCalculationLimits();

  const navigate = useNavigate();

  // find impact method for LCA
  const impactMethodId = scenarioTemplate?.assessmentConfigurations.filter((type) => type.assessmentType === 'LCA')[0]
    .impactMethodId;

  // fetch indicators list
  const { data: impactList } = useGetImpactMethodByIdQuery(
    impactMethodId ? { impactMethodId: impactMethodId as string } : skipToken,
  );

  const [scenarioTitle, setScenarioTitle] = useState('');
  const [scenarioDescription, setScenarioDescription] = useState('');

  const existAssessmentType = (typeValue) => {
    return scenarioTemplate
      ? scenarioTemplate.assessmentConfigurations.some((type) => type.assessmentType === typeValue)
      : false;
  };

  const methodology = scenarioTemplate.assessmentConfigurations
    .filter((type) => type.assessmentType === 'LCA')
    .map((type) => type.impactMethodRefId)[0];

  const methodologiesList: string[] = [
    '2dd484cd-412a-452f-bda7-bdb28883f1ef',
    '7b539e98-a49e-4814-9149-b87e4f1a5352',
    '6977bdea-9597-4833-a4df-3d2c2d72973a',
    '8b4a0911-1fd2-40bb-a0f2-5d9975491584',
  ];

  const assessmentTypeStatus = {
    LCA: existAssessmentType('LCA'),
    LCC: existAssessmentType('LCC'),
  };

  const initialPercentage = (assessmentType) => {
    const { LCA, LCC } = assessmentTypeStatus;
    if (LCA && LCC) {
      return 50;
    } else if (LCA) {
      return assessmentType === 'LCA' ? 100 : 0;
    } else if (LCC) {
      return assessmentType === 'LCC' ? 100 : 0;
    } else {
      return 0;
    }
  };

  const [sustainabilityPercentage, setSustainabilityPercentage] = useState(initialPercentage('LCA'));
  const [profitPercentage, setProfitPercentage] = useState(initialPercentage('LCC'));
  const [numberOfScenarios, setNumberOfScenarios] = useState(1);

  const isMultiSelectCondition = methodologiesList.includes(methodology);

  const indicatorsOptions = useMemo(() => {
    const options = impactList
      ? impactList.impactCategories.map((impact) => ({
          value: impact.name,
          label: impact.name,
        }))
      : [];

    if (isMultiSelectCondition) {
      options.push({ value: '', label: 'All indicators' });
    }

    return options;
  }, [impactList]);

  const algorithmsOptions = useMemo(() => {
    const algorithmsOptions: string[] = ['binary', 'brute'];
    const algorithmsLabels = ['Binary Search', 'Brute Force'];
    return algorithmsOptions.map((i, index) => ({ label: algorithmsLabels[index], value: i }));
  }, []);

  const [selectedIndicatorOptions, setSelectedIndicatorsOptions] = useState(
    isMultiSelectCondition ? [''] : indicatorsOptions?.map((option) => option.label)[0],
  );

  const handleSelectChange = (selectedOptions) => {
    if (selectedOptions.length === 0) {
      setSelectedIndicatorsOptions(['']);
    } else {
      if (isMultiSelectCondition) {
        const filteredOptions = selectedOptions.filter((option) => option !== '');
        setSelectedIndicatorsOptions(filteredOptions);
      } else {
        setSelectedIndicatorsOptions(selectedOptions);
      }
    }
  };

  const executionTime = useMemo(() => {
    if (algorithmType === 'binary') {
      if (currentNumberOfBinaryCombinations == 0) {
        return '0';
      } else {
        return executionTimeBinary;
      }
    } else if (algorithmType === 'brute') {
      if (currentNumberOfBinaryCombinations == 0) {
        return '0';
      } else {
        return executionTimeBrute;
      }
    }
  }, [algorithmType, executionTimeBinary, executionTimeBrute]);

  //TODO
  // execute optimization in background, how to catch errors?
  const handleSubmit = async () => {
    // await save of the customization
    await handleSaveCustomization();

    postAdvisoryRequest({
      algorithm: algorithmType,
      scenarioId: scenarioId,
      sustainabilityPercentage: sustainabilityPercentage,
      profitPercentage: profitPercentage,
      numberOfScenarios: numberOfScenarios,
      indicators: selectedIndicatorOptions,
      scenarioTitle: scenarioTitle,
      scenarioDescription: scenarioDescription,
    });
    navigate(`/app/projects/${projectId}`);
  };

  const advisoryItem: MenuItem = {
    name: 'Execute Optimization',
    description: 'Run the optimization algorithm',
    onClick: handleSubmit,
    Icon: HiOutlineArrowDownOnSquare,
  };

  return (
    <Fragment>
      <AdvisoryNavBar
        className="border-gray-200 border-b rounded-t p-2"
        advisoryItem={advisoryItem}
        disabled={!isWithinLimit}
        scenarioErrors={scenarioErrors}
        assessmentTypeStatus={assessmentTypeStatus}
      />
      <div className="bg-white p-2 px-3 gap-y-5 flex flex-col">
        <SimpleSelect
          label={'Algorithm type'}
          options={algorithmsOptions}
          value={algorithmType.toString()}
          onChange={(e) => setAlgorithmType(e)}
        />

        <InputNumber
          label={'Sustainability Percentage'}
          description={'Percentage of interest relating to sustainability'}
          value={sustainabilityPercentage}
          onChange={(e) => {
            if (assessmentTypeStatus.LCC) {
              const newValue = e.target.value;
              setSustainabilityPercentage(newValue);
              setProfitPercentage(100 - newValue);
            }
          }}
          disabled={!assessmentTypeStatus.LCA}
          min={0}
          max={100}
          step={5}
        />

        <InputNumber
          label={'Profit Percentage'}
          description={'Percentage of interest relating to profit'}
          value={profitPercentage}
          onChange={(e) => {
            if (assessmentTypeStatus.LCA) {
              const newValue = e.target.value;
              setProfitPercentage(newValue);
              setSustainabilityPercentage(100 - newValue);
            }
          }}
          disabled={!assessmentTypeStatus.LCC}
          min={0}
          max={100}
          step={5}
        />

        <InputNumber
          label={'Number of best scenarios'}
          description={'Number of best scenarios you want to output'}
          value={numberOfScenarios}
          onChange={(e) => setNumberOfScenarios(e.target.value)}
          min={1}
          max={5}
        />

        <SimpleSelect
          isMulti={isMultiSelectCondition}
          description={
            isMultiSelectCondition
              ? 'Impact indicators on which to perform the algorithm, all are used by default'
              : 'Select a single impact indicator on which to perform the algorithm'
          }
          label={'Impact indicators'}
          options={indicatorsOptions}
          value={selectedIndicatorOptions}
          onChange={handleSelectChange}
        />

        <InputText
          label={'Scenario Title'}
          onChange={(e) => {
            setScenarioTitle(e.target.value);
          }}
        />

        <InputText
          label={'Scenario Description'}
          onChange={(e) => {
            setScenarioDescription(e.target.value);
          }}
        />

        <InputText label={'Estimated Execution Time'} value={executionTime} disabled={true} />

        <div className="relative">
          <label className="whitespace-nowrap bg-gradient-to-t from-[#F8FAFC] from-50%  via-transparent via-55% to-transparent absolute -top-[8px] z-2 left-4 px-1 text-xs text-gray-700 rounded">
            Current number of combinations
          </label>
          <div
            className="flex items-center justify-center space-x-4 rounded border-2 focus-visible:outline-none focus:border-blue-500"
            style={{ backgroundColor: '#F8FAFC' }}
          >
            <GaugeChart
              id={'binaryIndicator'}
              value={Math.floor((currentNumberOfBinaryCombinations / maxCombinationsBinary) * 100)}
              width={250}
              height={120}
              innerRadius={40}
              outerRadius={80}
              title="Binary Search"
              labelValue={scenarioErrors ? 0 : currentNumberOfBinaryCombinations}
            />
            <GaugeChart
              id={'bruteForceIndicator'}
              value={Math.floor((currentNumberOfCombinations / maxCombinationsBrute) * 100)}
              width={250}
              height={120}
              innerRadius={40}
              outerRadius={80}
              title="Brute Force"
              labelValue={scenarioErrors ? 0 : currentNumberOfCombinations}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
