import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ErrorBoundary } from 'react-error-boundary';

import { AppRoutes } from './routes';
import Notifications from './components/Notifications';
import { Provider } from 'react-redux';
import { store } from './store';
import { Button } from './components/Elements';
// import { AuthProvider } from "./lib/auth";
import { BreadcrumbProvider } from './hooks/breadcrumbs';
import AuthProvider from './features/auth/AuthProvider';
import RoleProvider from './features/roles/hooks/RoleContext';

const ErrorFallback = () => {
  return (
    <div
      className="text-red-500 flex flex-col justify-center items-center"
      role="alert"
      style={{ maxHeight: 'calc(100vh/var(--zoom))', width: 'calc(100vw/var(--zoom))' }}
    >
      <h2 className="text-lg font-semibold">Ooops, something went wrong </h2>
      <Button className="mt-4" onClick={() => window.location.assign(window.location.origin + '/login')}>
        Refresh
      </Button>
    </div>
  );
};

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <HelmetProvider>
        <Provider store={store}>
          <BreadcrumbProvider>
            <Notifications />
            <BrowserRouter>
              <AuthProvider>
                <RoleProvider>
                  <AppRoutes />
                </RoleProvider>
              </AuthProvider>
            </BrowserRouter>
          </BreadcrumbProvider>
        </Provider>
      </HelmetProvider>
    </ErrorBoundary>
  );
}

export default App;
