import { HiArrowPath, HiPlusCircle } from 'react-icons/hi2';
import classNames from '../../../utils/classNames';
import { Roled } from '../../roles/hooks/Roled';
import { Link } from '../../../components/Elements';
import { LiaCubeSolid } from 'react-icons/lia';
import { RiDeleteBinLine, RiEditBoxLine } from 'react-icons/ri';
import { GrDuplicate } from 'react-icons/gr';
import React from 'react';

type CreateProjectButtonProps = {
  size?: string;
  className?: string;
  color?: 'dark-gray' | 'white' | 'light-gray';
  to: string;
  disabled?: boolean;
};

export const CreateProjectButton = (props: CreateProjectButtonProps) => {
  const { className, to, disabled } = props;

  return (
    <div className={className}>
      <Link
        to={to}
        disabled={disabled}
        /*className={'absolute flex w-full h-full text-gray-600 cursor-pointer content-center items-center text-center backdrop-blur-[4px] hover:bg-blue-100 hover:bg-opacity-60 hover:text-blue-600 hover:border-blue-400'}>*/
        className={
          'absolute flex w-full h-full text-gray-600 cursor-pointer bg-opacity-60 bg-white content-center items-center text-center hover:bg-blue-100 hover:bg-opacity-90 hover:text-blue-600 hover:border-blue-400 border border-gray-800 border-dashed disabled:border-0'
        }
      >
        <div className="absolute left-[40%]">
          <HiPlusCircle className="h-6 w-6 mr-2 float-left" /> Create New Project
        </div>
      </Link>

      <div className="block col-span-10 p-4 bg-white ">
        <div className="flex items-center">
          <p
            className={classNames(
              'flex-shrink truncate text-sm font-medium',
              disabled ? 'text-gray-500' : 'text-blue-600',
            )}
          >
            Project name
          </p>
          <span className="ml-2 rounded bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800">0</span>
        </div>
        <div className="mt-2 sm:flex sm:justify-between">
          <div className="sm:flex">
            <p className="flex items-center text-sm text-gray-500">Description</p>
          </div>
          <div className=" mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
            <p>{new Date().toDateString()}</p>
          </div>
        </div>
      </div>
      <div className="flex col-span-1 items-center bg-white">
        <RiEditBoxLine
          className={classNames('h-6 w-6 m-auto', disabled ? 'text-gray-500' : 'text-blue-600')}
        ></RiEditBoxLine>
      </div>
      <div className="flex col-span-1 items-center bg-white">
        <RiDeleteBinLine
          className={classNames('h-6 w-6 m-auto', disabled ? 'text-gray-500' : 'text-red-600')}
        ></RiDeleteBinLine>
      </div>
    </div>
  );
};
