import { Menu } from '@headlessui/react';
import { ReactNode } from 'react';
import classNames from '../../utils/classNames';
import { MenuItem, SeparatorItem } from './ActionMenu';

export type SeparatorProps = SeparatorItem & {};

export default function Separator(props: SeparatorProps) {
  const { className, title } = props;
  console.log(title, { className });
  return (
    <Menu.Item>
      <div
        className={classNames(
          className
            ? className
            : 'border-b-2 leading-[0.1em] line text-center gap-x-5 my-2 rounded px-2 text-sm z-50 font-bold',
        )}
      >
        <span className="bg-white p-2"> {title} </span>
      </div>
    </Menu.Item>
  );
}
