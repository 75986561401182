import React, { useCallback, useContext } from 'react';
import { useAuth } from '../../auth/AuthProvider';
import { useGetPermissionByRoleFromTokenQuery } from '../../../api/roleBasedAccessManager/roleBasedAccessManager';
import { skipToken } from '@reduxjs/toolkit/query';

type AuthProps = {
  children: JSX.Element;
};

export type AuthUser = {
  username: string;
  role: string;
};

interface RoleContextType {
  getProps: (name: string) => any;
}

const RoleContext = React.createContext<RoleContextType>({
  getProps: (name: string) => {},
});

function RoleProvider(props: AuthProps) {
  const { children } = props;

  const { user } = useAuth();
  const { data: permission, isLoading, error } = useGetPermissionByRoleFromTokenQuery(user ? {} : skipToken);

  const getProps = useCallback(
    (name: string) => {
      // console.log(configurations?.filter((c) => c.selector === name)[0]?.properties);
      return permission?.configurations?.filter((c) => c.selector === name)[0]?.properties;
    },
    [permission],
  );

  // console.log(isLoading, configurations);

  return <RoleContext.Provider value={{ getProps }}>{children}</RoleContext.Provider>;
}

export const useRoleContext = () => {
  const { getProps } = useContext<RoleContextType>(RoleContext);
  return {
    getProps,
  };
};

export default RoleProvider;
