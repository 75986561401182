import { useStateMachine } from 'little-state-machine';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  CustomizationSpace,
  Parameter,
  useGetCustomizationSpacesQuery,
} from '../../../../api/customizationSpace/customizationSpaceApi';
import { Button } from '../../../../components/Elements';
import { PhaseDto, useGetAllPhaseQuery } from '../../../../api/project/projectApi';

import initScenarioTemplateAction from './../../utils/initScenarioTemplateAction';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast/headless';
import {
  Commit,
  DisplayConfiguration,
  usePostCommitMutation,
  usePostScenarioTemplateMutation,
} from '../../../../api/scenarioTemplate/scenarioTemplateApi';
import PhaseCustomization from './PhaseCustomization';
import ParameterSection from './ParameterSection';
import { HiArrowDownOnSquare, HiDocumentDuplicate, HiOutlineEye } from 'react-icons/hi2';
import { yupResolver } from '@hookform/resolvers/yup';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { scenarioTemplateSteps } from './ScenarioTemplate';
import { basicScenarioTemplateSchema } from '../validation/basicScenarioTemplateSchema';
import VariableConfigurationsManager from '../BomScenarioTemplate/VariableConfigurationsManager';
import { Show } from '../../../../components/show/Show';
import { MapItems } from '../../../../components/map-items/MapItems';
import GuidedNavigation from '../../../../components/navigation/GuidedNavigation';
import { Tooltip } from 'react-tooltip';
import ButSaveModal from '../ButSaveModal';
import ButSlidePanel from '../../../../components/SlidePanel/ButSlidePanel';
import CommitList from '../CommitList';
import { FiGitCommit } from 'react-icons/fi';
import ParameterImporter from '../Parameter/ParameterImporter';
import ButModal from '../../../../components/Modal/ButModal';
import { BsFiletypeCsv } from 'react-icons/bs';
import {
  useCreateCombinationTreeV1SmosCreateCombinationTreeTemplateIdPostMutation,
  useHasCombinationTreeV1SmosHasCombinationTreeTemplateIdGetQuery,
} from '../../../../api/advisory/advisoryApi';
import { skipToken } from '@reduxjs/toolkit/query';

function ScenarioTemplateCustomization() {
  const navigate = useNavigate();
  const { actions, state } = useStateMachine({ initScenarioTemplateAction });

  const { data: phases } = useGetAllPhaseQuery({});
  const [postScenarioTemplate] = usePostScenarioTemplateMutation();
  const [postCommit] = usePostCommitMutation();
  const [postMetaSpace] = useCreateCombinationTreeV1SmosCreateCombinationTreeTemplateIdPostMutation();
  const { data: customizationSpaces } = useGetCustomizationSpacesQuery({
    customizationSpaceRefIds: undefined,
  });

  const [postedMetaSpace, setPostedMetaSpace] = useState(false);

  useEffect(() => {
    if (!state.template) {
      navigate(-1);
    } else {
      console.log({ startingTemplate: state.template });
    }
  }, []);

  const methods = useForm({
    defaultValues: { ...state.template },
    resolver: (values, context, options) =>
      yupResolver(basicScenarioTemplateSchema)(
        values,
        {
          ...values,
        },
        options,
      ),
    mode: 'onChange',
  });
  const {
    setValue,
    trigger,
    control,
    watch,
    getValues,
    formState: { errors },
  } = methods;

  const { data: hasCombinationTree, error: hasCombinationTreeError } =
    useHasCombinationTreeV1SmosHasCombinationTreeTemplateIdGetQuery(
      postedMetaSpace ? { templateId: getValues().id as string } : skipToken,
    );

  const { append: appendParameter } = useFieldArray({
    control,
    name: 'parameters',
  });

  const parameters: Parameter[] = watch('parameters');
  const displayConfigurations: DisplayConfiguration[] = watch('displayConfigurations');

  const handleParameterCreation = useCallback(
    (toCreate: Parameter[]) => {
      const items = toCreate.filter((v, index) => {
        if (parameters?.some((e: Parameter) => e.parameterName === v.parameterName)) {
          toast.error(`Parameter: ${v.parameterName} already exists. Skipping it.`);
          console.warn(`Parameter: ${v.parameterName} already exists. Skipping it.`);
          return false;
        }
        return true;
      });
      appendParameter(items);
    },
    [appendParameter, parameters],
  );

  const handleNavigate = (href: string) => {
    actions.initScenarioTemplateAction({ template: getValues() });
    navigate(href);
  };

  const handleFormSubmit = async (values: any, valid: boolean, commit: Commit) => {
    console.log('Template', { values });

    values.valid = valid;
    const scenarioTemplate: any = await postScenarioTemplate({ body: values });

    if (scenarioTemplate.error) {
      console.log(scenarioTemplate.error);
      toast.error('Failed to create scenario template');
    } else {
      postCommit({
        commit: {
          ...commit,
          message: `${commit?.message}${!valid ? ' [saved with error]' : ''}`,
          scenarioTemplateId: scenarioTemplate.data.id,
        } as Commit,
      });
      if (!values.id) {
        setValue('id', scenarioTemplate.id);
      }
      toast.success('Scenario template created successfully');
    }

    const metaSpace = await postMetaSpace({ templateId: scenarioTemplate.data.id as string });
    if (metaSpace.error) {
      console.log(metaSpace.error);
      toast.error('Failed to create metaspace');
    } else {
      setPostedMetaSpace(true);
    }

    // // check if the metaspace combinations exist
    // if (!hasCombinationTree) {
    //   toast.error('Cannot optimize this scenario template');
    // }
    toast.success('Metaspace combinations created successfully');
  };

  const handleTrigger = async () => {
    const result = await trigger();
    if (result) {
      toast.success('Scenario template is valid');
    } else {
      console.error({ errors });
      toast.error('Scenario template contains some errors');
    }
  };

  const fieldsMethod = useFieldArray({
    control,
    name: 'parameters',
  });

  useEffect(() => {
    trigger();
  }, []);

  return (
    <FormProvider {...methods}>
      <GuidedNavigation
        steps={scenarioTemplateSteps}
        callback={(href: string) => handleNavigate(href)}
        className="mt-2"
      />
      <form className="flex flex-col mt-2 bg-white shadow rounded">
        <div className="mt-2 flex ml-auto">
          <Tooltip variant={'info'} content="Parameters import" anchorSelect={'#import'} className="float-right mr-2" />
          <ButModal
            id={'import'}
            label={<BsFiletypeCsv className="focus:outline-none h-8 w-8 cursor-pointer mr-2" />}
            size={'xl'}
          >
            <ParameterImporter onAdd={fieldsMethod.append} displayConfigurations={displayConfigurations} />
          </ButModal>
          <Tooltip variant={'info'} content="Save" anchorSelect={'#save'} className="float-right mr-2" />
          <ButSaveModal
            buttonLabel={<HiArrowDownOnSquare id={'save'} className="focus:outline-none w-8 h-8 cursor-pointer mr-2" />}
            onFormSubmit={handleFormSubmit}
            scenarioTemplateId={state.template?.id}
          />
          <Tooltip variant={'info'} content="Copy" anchorSelect={'#copy'} className="float-right mr-2 " />
          <CopyToClipboard text={JSON.stringify(getValues())} onCopy={() => toast.success('Copied to clipboard')}>
            <HiDocumentDuplicate id={'copy'} className="focus:outline-none w-8 h-8 cursor-pointer mr-2" />
          </CopyToClipboard>
          <Tooltip variant={'info'} anchorSelect={'#info'} content="Commits" />
          {state.template?.id && (
            <ButSlidePanel
              id={'info'}
              buttonSize={'none'}
              buttonLabel={<FiGitCommit className={'h-8 w-8 cursor-pointer mr-2'} />}
              label={'Changes on the scenario template'}
            >
              <CommitList scenarioTemplateId={state.template?.id} />
            </ButSlidePanel>
          )}
          <Tooltip variant={'info'} anchorSelect={'#validate'} content="Validate" />
          <HiOutlineEye
            className="h-8 w-8 cursor-pointer focus:outline-none mr-2"
            id={'validate'}
            onClick={() => handleTrigger()}
          />
        </div>
        <div className="flex-1 mt-4 px-2">
          <VariableConfigurationsManager
            prefix={'variableConfigurations'}
            parameters={parameters}
            className={'grid-cols-2'}
          />
        </div>
        <div className="bg-white mt-2 rounded">
          <ParameterSection
            type={'AMOUNT_CHANGE'}
            {...fieldsMethod}
            item={{
              parameterName: '',
              type: 'AMOUNT_CHANGE',
              parameterType: 'NUMERIC',
            }}
            displayConfigurations={displayConfigurations}
            name={'Amount change parameters'}
          />
        </div>
        <div className="bg-white mt-2 rounded">
          <ParameterSection
            type={'ADDITIONAL_PARAMETER'}
            {...fieldsMethod}
            item={{
              parameterName: '',
              type: 'ADDITIONAL_PARAMETER',
              parameterType: 'NUMERIC',
            }}
            name={'Additional parameters'}
            displayConfigurations={displayConfigurations}
          />
        </div>
        <div className="bg-white mt-2 rounded">
          <ParameterSection
            item={{
              parameterName: '',
              type: 'FLOW_SWITCH',
              parameterType: 'OPTION',
            }}
            {...fieldsMethod}
            type={'FLOW_SWITCH'}
            name={'Flow switches'}
            displayConfigurations={displayConfigurations}
          />
        </div>
        <Show>
          <Show.When isTrue={customizationSpaces && customizationSpaces.length > 0 && phases}>
            <MapItems items={state.template?.displayConfigurations}>
              {(dc: DisplayConfiguration, index) => (
                <PhaseCustomization
                  key={dc.phaseId}
                  phase={phases?.find((p) => p.id === dc.phaseId) as PhaseDto}
                  displayConfiguration={dc}
                  prefix={`displayConfigurations.${index}`}
                  onParameterCreation={handleParameterCreation}
                  customizationSpaces={customizationSpaces as CustomizationSpace[]}
                />
              )}
            </MapItems>
          </Show.When>
        </Show>
      </form>
      <Button onClick={() => handleNavigate(`/app/templates/BASIC/${state.template.id ? state.template.id : 'new'}`)}>
        Back
      </Button>
    </FormProvider>
  );
}

export default ScenarioTemplateCustomization;
