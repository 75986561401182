import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Reports } from './Reports';
import { StateMachineProvider } from 'little-state-machine';
import Report from '../components/Report';
import CreateReport from '../components/CreateReport';

export const ReportRoutes = () => {
  return (
    <StateMachineProvider>
      <Routes>
        <Route path="" element={<Reports />} />
        <Route path=":reportId" element={<Report />} />
        <Route path="/edit/:reportId" element={<Report />} />
        <Route path="/new" element={<CreateReport />} />
      </Routes>
    </StateMachineProvider>
  );
};
