import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useBreadcrumbs } from '../../../hooks/breadcrumbs';
import { toast } from 'react-hot-toast/headless';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { PermissionForm } from './PermissionForm';
import {
  useGetPermissionByRoleQuery,
  usePostPermissionMutation,
} from '../../../api/roleBasedAccessManager/roleBasedAccessManager';
import { Spinner } from '../../../components/Elements';

function Permission() {
  const { role } = useParams();

  const { data: permission, isLoading } = useGetPermissionByRoleQuery(role ? { role: role } : skipToken);

  const [postPermission] = usePostPermissionMutation();

  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([{ label: 'permissions', path: '/app/permissions' }, { label: `${permission?.role}` }]);
  }, [permission]);

  const onSubmit = async (permission: any) => {
    const updatedPermission: any = await postPermission({
      permission: permission,
    }).unwrap();
    if (updatedPermission.error) {
      // console.log(updatedScenario.error);
      toast.error('Failed to update permission');
    } else {
      toast.success('Permission updated successfully');
    }
  };

  if (isLoading) {
    return <Spinner size={'h-14 w-14'} className="w-12 h-12 self-center m-5" />;
  }

  return <PermissionForm onSubmit={onSubmit} initialValues={permission} submitLabel={'Update'} />;
}

export default Permission;
