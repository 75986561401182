import { Link as RouterLink, LinkProps } from 'react-router-dom';
import React, { Fragment } from 'react';
import classNames from '../../../utils/classNames';

const disabledStyle =
  ' disabled:opacity-30 disabled:bg-gray-300 disabled:border-0 disabled:border-gray-100 hover:disabled:bg-gray-400  hover:disabled:text-gray-900 disabled:cursor-not-allowed disabled:text-gray-500';

type CustomLinkProps = LinkProps & {
  disabled?: boolean;
};

export const Link = ({ className, children, disabled, ...props }: CustomLinkProps) => {
  return (
    <Fragment>
      {!!disabled ? (
        <button className={classNames(className ? className : '', disabledStyle)} disabled>
          {children}
        </button>
      ) : (
        <RouterLink className={className} {...props}>
          {children}
        </RouterLink>
      )}
    </Fragment>
  );
};
