import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CustomizationSpace } from '../../../api/customizationSpace/customizationSpaceApi';
import { useGetProjectQuery, useGetScenarioQuery } from '../../../api/project/projectApi';
import {
  BasicScenarioTemplate,
  BomScenarioTemplate,
  useGetScenarioTemplateQuery,
} from '../../../api/scenarioTemplate/scenarioTemplateApi';
import '../style/style.css';
import BomCustomization from './BomCustomization';
import BasicCustomization from './BasicCustomization';
import { FormProvider, useForm } from 'react-hook-form';
import { BreadcrumbItem, useBreadcrumbs } from '../../../hooks/breadcrumbs';
import { CustomizationTemplateProvider } from '../hooks/CustomizationTemplateProvider';
import useRenderCounter from '../../../hooks/useRenderCounter';
import { AdvisoryLimitationProvider } from '../hooks/AdvisoryLimitationProvider';

export type PhaseStatusDTO = {
  phaseId: string;
  status: any;
  customizationSpace?: CustomizationSpace[];
};

export default function Customization() {
  const { scenarioId, projectId } = useParams();

  const [triggerAssessment, setTriggerAssessment] = useState(false);

  const { data: scenario } = useGetScenarioQuery(scenarioId ? { scenarioId } : skipToken);
  const { data: project } = useGetProjectQuery(projectId ? { projectId } : skipToken);
  const { data: scenarioTemplate } = useGetScenarioTemplateQuery(
    scenario?.scenarioTemplateId ? { scenarioTemplateId: scenario.scenarioTemplateId } : skipToken,
  );

  const identifier = useMemo(() => `single${scenario?.id}`, [scenario]);
  const renderCounter = useRenderCounter();
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    const breadCrumbs: BreadcrumbItem[] = [];
    if (project && scenario) {
      breadCrumbs.push(
        { label: 'projects', path: '/app/projects' },
        { label: project.name as string, path: `/app/projects/${project.id}` },
        { label: scenario.name as string },
      );
    } else if (scenario) {
      breadCrumbs.push({ label: 'scenarios', path: '/app/scenarios' }, { label: scenario.name as string });
    }

    setBreadcrumbs(breadCrumbs);
  }, [scenario, project]);

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const scenarioErrors = useMemo(() => {
    return Object.keys(methods.formState.errors).length > 0;
  }, [methods.formState]);

  return (
    <FormProvider {...methods}>
      {renderCounter}
      {scenario && scenarioTemplate && (
        <CustomizationTemplateProvider key={identifier} identifier={identifier}>
          <AdvisoryLimitationProvider>
            <>
              {scenarioTemplate?.type === 'BASIC' ? (
                <BasicCustomization
                  scenario={scenario}
                  scenarioTemplate={scenarioTemplate as BasicScenarioTemplate}
                  triggerAssessment={triggerAssessment}
                  fromComparison={false}
                />
              ) : (
                <BomCustomization
                  scenario={scenario}
                  identifier={identifier}
                  scenarioTemplate={scenarioTemplate as BomScenarioTemplate}
                  triggerAssessment={triggerAssessment}
                  fromComparison={false}
                />
              )}
            </>
          </AdvisoryLimitationProvider>
        </CustomizationTemplateProvider>
      )}
    </FormProvider>
  );
}
