import { HiMagnifyingGlass, HiPlusCircle } from 'react-icons/hi2';
import React, { useEffect, useState } from 'react';
import { useGetAllScenarioQuery } from '../../../api/project/projectApi';
import { Link } from '../../../components/Elements';
import { InputText } from '../../../components/input';
import { useBreadcrumbs } from '../../../hooks/breadcrumbs';
import ListScenario from '../components/ListScenario';
import { RiDeleteBinLine, RiEditBoxLine } from 'react-icons/ri';
import { GrDuplicate } from 'react-icons/gr';
import { LiaCubeSolid } from 'react-icons/lia';
import { useAuth } from '../../auth/AuthProvider';
import { Roled } from '../../roles/hooks/Roled';
import { CreateScenarioButton } from '../components/CreateScenarioButton';

export const Scenarios = () => {
  const [search, setSearch] = useState<string>();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { user } = useAuth();

  useEffect(() => {
    setBreadcrumbs([{ label: 'scenarios' }]);
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row ml-auto my-3">
        <InputText
          addonBefore={<HiMagnifyingGlass className="w-6 h-6 text-black" />}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        {/*<Link to="./new/">
          <Button className={'ml-3'} style={{ height: '46px' }} variant="inverse" size={'md'}>
            <HiPlusCircle className="mr-2 h-6 w-6" /> Create New
          </Button>
        </Link>*/}
      </div>
      <Roled name={'create-scenario'}>
        <CreateScenarioButton className={'relative grid grid-cols-12'} to={'./new'} />
      </Roled>
      <ListScenario filter={{ scenarioName: search, scenarioDescription: search }} fetchData={useGetAllScenarioQuery} />
    </div>
  );
};
