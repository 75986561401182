import { Menu } from '@headlessui/react';
import classNames from '../../utils/classNames';
import { MenuItem } from './ActionMenu';

export type MenuItemProps = MenuItem & {};

export default function Item(props: MenuItemProps) {
  const { className, name, description, Icon, onClick } = props;

  return (
    <Menu.Item key={name}>
      {({ active }) => (
        <div
          className={classNames(
            className ? className : '',
            'cursor-pointer group relative flex gap-x-5 rounded p-3 hover:bg-gray-50 text-sm z-50',
          )}
        >
          {Icon && (
            <div className="mt-1 flex h-8 w-8 flex-none items-center justify-center rounded">
              <Icon className="h-6 w-6 text-gray-600 group-hover:text-blue-600" aria-hidden="true" />
            </div>
          )}
          <div>
            {name && (
              <div onClick={onClick} className="font-semibold text-gray-900">
                {name}
                <span className="absolute inset-0" />
              </div>
            )}
            {description && <p className="text-xs text-gray-500">{description}</p>}
          </div>
        </div>
      )}
    </Menu.Item>
  );
}
