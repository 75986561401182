import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Parameter } from '../../../api/customizationSpace/customizationSpaceApi';
import {
  CustomizedBasicScenario,
  FlowSwitchParameter,
  FormulaParameter,
  RemoteParameter,
  useGetCustomizedProcessQuery,
  usePostCustomizedBasicScenarioMutation,
  useUpsertCustomizedProcessMutation,
} from '../../../api/customizedScenario/customizedScenarioApi';
import { ScenarioDto, usePostScenarioMutation } from '../../../api/project/projectApi';
import '../style/style.css';
import { createBasicCustomizationPayload } from '../utils/createBasicCustomizationPayload';
import CustomizationTemplateForm, { IndexedParameter } from './CustomizationTemplateForm';
import toast from 'react-hot-toast/headless';
import {
  useCalculateBasicCustomizedScenarioWithPayloadMutation,
  useCalculateBasicHotSpotsMutation,
} from '../../../api/lightweightCalculationEngine/lightweightCalculationEngineApi';
import { HiOutlineArrowDownOnSquare } from 'react-icons/hi2';
import { MenuItem } from '../../../components/ActionMenu/ActionMenu';
import useRenderCounter from '../../../hooks/useRenderCounter';
import { useFormContext } from 'react-hook-form';
import { skipToken } from '@reduxjs/toolkit/query';
import { HiFire, HiOutlineSaveAs } from 'react-icons/hi';
import { LiaRobotSolid } from 'react-icons/lia';
import Modal from '../../../components/Modal/Modal';
import { InputText } from '../../../components/input';
import { debounce } from 'lodash';
import { BasicScenarioTemplate, DisplayConfiguration } from '../../../api/scenarioTemplate/scenarioTemplateApi';
import { useCustomizationTemplateContext } from '../hooks/CustomizationTemplateProvider';
import { HotspotPopup } from './advisory/HotspotPopup';
import { AdvisoryPopup } from './advisory/AdvisoryPopup';
import CustomizationNavBar from './CustomizationNavBar';
import classNames from '../../../utils/classNames';

export type BasicCustomizationProps = {
  scenario: ScenarioDto;
  scenarioTemplate: BasicScenarioTemplate;
  triggerAssessment?: boolean;
  onAutoFetch?: (assessment: any, parameters: Parameter[]) => any;
  fromComparison: boolean;
};

export default function BasicCustomization(props: BasicCustomizationProps) {
  const { scenario, scenarioTemplate, triggerAssessment, onAutoFetch, fromComparison } = props;

  const navigate = useNavigate();
  const renderCounter = useRenderCounter();
  const { formState, getValues, reset, trigger } = useFormContext();
  const {
    advisory,
    hotspot,
    setHotspot,
    showHotspotPopup,
    setShowHotspotPopup,
    handleAdvisoryMode,
    handleHotspotMode,
    setScenarioId,
    setProjectId,
    useHasCombinationTree,
  } = useCustomizationTemplateContext();

  const [phaseId, setPhaseId] = useState<string>();
  const [openSaveAs, setOpenSaveAs] = useState<boolean>(false);
  const [saveAsName, setSaveAsName] = useState<string>();
  const [initted, setInitted] = useState<boolean>(false);

  const [upsertCustomizedProcess] = useUpsertCustomizedProcessMutation();
  const [postCustomizedBasicScenario] = usePostCustomizedBasicScenarioMutation();
  const [calculateBasicCustomizedScenarioWithPayload] = useCalculateBasicCustomizedScenarioWithPayloadMutation();
  const { data: customizedProcess, isLoading } = useGetCustomizedProcessQuery(
    scenario.id ? { scenarioId: scenario.id as string } : skipToken,
  );

  const [postHotspot] = useCalculateBasicHotSpotsMutation();
  const [postScenario] = usePostScenarioMutation();
  const isAiBased = useMemo(() => scenario.aiBased as boolean, [scenario]);
  const scenarioErrors = Object.keys(formState.errors).length > 0;
  const hasCombinationTree = useHasCombinationTree(scenario.scenarioTemplateId as string);

  useEffect(() => {
    setScenarioId(scenario.id as string);
    setProjectId(scenario.projectId as string);
  }, [scenario]);

  useEffect(() => {
    if (triggerAssessment) {
      goToAssessment();
    }
  }, [triggerAssessment]);

  useEffect(() => {
    if (!isLoading) {
      let initialState: { parameters: IndexedParameter[] } = {
        parameters: scenarioTemplate?.parameters?.map((p, index) => ({
          ...p,
          index,
        })) as IndexedParameter[],
      };
      if (customizedProcess && customizedProcess?.refId === scenario.id) {
        customizedProcess.customization?.['parameters']?.forEach((customizationParameter: Parameter) => {
          const parameter = initialState.parameters.find(
            (p) => p.parameterName === customizationParameter.parameterName,
          );
          if (parameter) {
            parameter.value = customizationParameter.value;
            parameter.static = customizationParameter.static;
            parameter.max = customizationParameter.max;
            parameter.step = customizationParameter.step;
            parameter.options_list = customizationParameter.options_list;
          }
        });
      }
      reset(initialState);
      setInitted(true);
    }
  }, [customizedProcess]);

  useEffect(() => {
    if (initted) {
      handleFormChange();
      setInitted(false);
    }
  }, [initted]);

  const handleFormChange = useCallback(
    debounce(async () => {
      const valid = await trigger();
      if (onAutoFetch && valid) {
        onAutoFetch(executeAssessment(true), getValues().parameters);
        // console.log('handleFormChange - executeAssessment');
      }
    }, 150),
    [],
  );

  const goToAssessment = async () => {
    if (!(Object.keys(formState.errors).length > 0)) {
      const save = await handleSave();
      if (save) {
        const url = `/app/assessments/${scenario?.id}`;
        fromComparison ? window.open(url, '_blank') : navigate(url);
      } else {
        toast.error('Something went wrong during saving scenario');
      }
    } else {
      toast.error('Unable to execute assessments!');
    }
  };

  const createPayload = () => {
    if (!(Object.keys(formState.errors).length > 0)) {
      const parameters: (FlowSwitchParameter | RemoteParameter | FormulaParameter)[] = createBasicCustomizationPayload(
        getValues().parameters,
        scenarioTemplate.variableConfigurations,
      );
      return {
        parameters,
        assessmentConfigurations: scenarioTemplate.assessmentConfigurations,
        variableConfigurations: scenarioTemplate.variableConfigurations,
      };
    }
    return undefined;
  };

  const executeAssessment = async (shallow: boolean) => {
    const payload = createPayload();
    if (payload) {
      const calculation = await calculateBasicCustomizedScenarioWithPayload({
        customizedBasicScenarioDto: payload,
        shallow,
      });
      if ('error' in calculation) {
        toast.error(calculation.error.data.message);
        // throw new Error('Failed to execute assessment: ' + calculation.error);
      }
      // If there is no error, we can safely assume that the data property exists
      return calculation.data;
    }
  };

  const handleSave = async () => {
    await upsertCustomizedProcess({
      customizedProcess: {
        refId: scenario?.id as string,
        customization: getValues(),
      },
    });
    const payload: CustomizedBasicScenario | undefined = createPayload();
    toast.success('Scenario successfully saved!');
    if (payload) {
      await postCustomizedBasicScenario({
        customizedBasicScenario: {
          scenarioId: scenario?.id,
          ...payload,
        },
      });
      return true;
    }
    return false;
  };

  const handleSaveAs = async () => {
    const newScenario: ScenarioDto = await postScenario({
      scenarioDto: {
        name: saveAsName,
        projectId: scenario.projectId,
        scenarioTemplateId: scenario.scenarioTemplateId,
        description: scenario.description,
      },
    }).unwrap();
    upsertCustomizedProcess({
      customizedProcess: {
        refId: newScenario?.id as string,
        customization: getValues(),
      },
    });

    if ('error' in newScenario) {
      toast.error('Failed to create new scenario');
    } else {
      toast.success('Scenario created successfully');
    }
    setSaveAsName(undefined);
    setOpenSaveAs(false);
  };

  const existAssessmentType = (typeValue) => {
    return scenarioTemplate.assessmentConfigurations?.some((type) => type.assessmentType === typeValue);
  };

  const items: MenuItem[] = [
    {
      name: 'Save Customization',
      description: 'Save the current customization',
      onClick: handleSave,
      Icon: HiOutlineArrowDownOnSquare,
    },
    {
      name: 'Save as',
      description: 'Save as a new scenario',
      onClick: () => setOpenSaveAs(true),
      Icon: HiOutlineSaveAs,
    },
  ];

  // add to the menu only if the scenario is not AI based and has a combination tree
  if (!isAiBased && hasCombinationTree && existAssessmentType('LCA')) {
    items.push({
      name: advisory ? 'Disable Advisory tool' : 'Enable Advisory tool',
      description: 'Use AI to calculate the best scenario',
      onClick: () => handleAdvisoryMode(scenarioErrors, existAssessmentType('LCA')),
      Icon: LiaRobotSolid,
    });
  }

  // add hotspot to the menu only if the scenario has LCA assessment type
  if (existAssessmentType('LCA')) {
    items.push({
      name: hotspot ? 'Disable Hotspot identification' : 'Enable Hotspot identification',
      description: 'Use AI to identify hotspots',
      onClick: () => handleHotspotMode(scenarioErrors, existAssessmentType('LCA')),
      Icon: HiFire,
      disabled: scenarioErrors,
    });
  }

  const assessmentItem: MenuItem = {
    name: 'Save Customization',
    description: 'Save the current customization',
    onClick: goToAssessment,
    Icon: HiOutlineArrowDownOnSquare,
  };

  return (
    <div className={'flex space-x-2'}>
      {renderCounter}
      <div className={'flex-[3] flex flex-col overflow-x-scroll min-w-[50%]'}>
        {isLoading && <div className={'h-full w-full absolute block z-50 cursor-wait'}></div>}
        <CustomizationNavBar
          scenario={scenario}
          items={items}
          assessmentItem={assessmentItem}
          className={classNames(fromComparison ? '' : 'mt-3', 'rounded-t p-2')}
        />

        <CustomizationTemplateForm
          className="rounded-b"
          parameters={
            scenarioTemplate.parameters?.map((p, index) => ({
              ...p,
              index,
            })) as IndexedParameter[]
          }
          displayConfigurations={scenarioTemplate.displayConfigurations as DisplayConfiguration[]}
          currentPhaseId={phaseId as string}
          changePhase={setPhaseId}
          onFormChange={handleFormChange}
          fromComparison={fromComparison}
        />
      </div>
      {advisory && (
        <div className={'flex-1 mt-3 rounded-b bg-white'}>
          <AdvisoryPopup
            scenarioErrors={scenarioErrors}
            scenarioTemplate={scenarioTemplate as BasicScenarioTemplate}
            handleSaveCustomization={handleSave}
          />
        </div>
      )}

      {!scenarioErrors && existAssessmentType('LCA') && (
        <HotspotPopup
          show={showHotspotPopup}
          handleOpen={setShowHotspotPopup}
          handleHotspotMode={setHotspot}
          scenarioTemplate={scenarioTemplate as BasicScenarioTemplate}
          handleSubmit={postHotspot}
          handleSaveCustomization={handleSave}
        />
      )}

      <Modal open={openSaveAs} handleOpen={setOpenSaveAs} confirm={saveAsName ? handleSaveAs : undefined}>
        <InputText value={saveAsName} onChange={(e) => setSaveAsName(e.target.value)} label={'New scenario name'} />
      </Modal>
    </div>
  );
}
