import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

type ModalProps = {
  children: React.ReactNode;
  open: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  handleOpen: (open: boolean) => void;
  confirm?: () => void;
  label?: string;
  cancel?: () => void;
};

const modelSize = {
  xs: 'col-span-3 col-start-6',
  sm: 'col-span-4 col-start-5',
  md: 'col-span-6 col-start-4',
  lg: 'col-span-8 col-start-3',
  xl: 'col-span-10 col-start-2',
  advisory: 'col-span-6 col-start',
};

export default function Modal(props: ModalProps) {
  const { children, cancel, open, size, label, confirm, handleOpen, disabled } = props;

  const actualSize = size ? modelSize[size] : modelSize['md'];

  const handleClose = () => {
    cancel?.();
    console.log('Handling open');
    handleOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10">
          <div className="grid grid-cols-12 min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Panel
                className={`${actualSize} overflow-y-scroll rounded bg-white px-4 pb-4 pt-5 text-left shadow sm:p-6 max-h-[80vh]`}
              >
                {children}
                {confirm ? (
                  <div className="mt-5 sm:mt-6 flex">
                    <button
                      type="button"
                      className="bg-red-500 text-white p-2 m-2 rounded hover:bg-blue-600 hover:text-white flex-1"
                      onClick={() => confirm?.()}
                      disabled={disabled}
                    >
                      {label ? label : 'Confirm'}
                    </button>

                    <button
                      type="button"
                      className="bg-white text-blue-500 border-2 border-blue-500 p-2 m-2 rounded hover:bg-blue-600 hover:text-white flex-1"
                      onClick={() => handleClose()}
                    >
                      No
                    </button>
                  </div>
                ) : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
