import React, { useMemo, useState } from 'react';
import Select from 'react-select';
import Modal from '../../../../components/Modal/Modal';
import { useCustomizationTemplateContext } from '../../hooks/CustomizationTemplateProvider';
import { useGetImpactMethodByIdQuery } from '../../../../api/lightweightOlcaCore/lightweightOlcaCoreApi';
import { skipToken } from '@reduxjs/toolkit/query';
import Slider from '../../../../components/Slider/Slider';
import { SimpleSelect } from '../../../../components/input/SimpleSelect';

export const HotspotPopup = (props) => {
  const { scenarioTemplate, show, handleOpen, handleHotspotMode, handleSubmit, handleSaveCustomization } = props;

  const { scenarioId, setCurrentHotspot } = useCustomizationTemplateContext();

  const [assessmentType, setAssessmentType] = useState('LCA');
  const [threshold, setThreshold] = useState(80);

  // find impact method for LCA
  const impactMethodId = scenarioTemplate?.assessmentConfigurations.filter((type) => type.assessmentType === 'LCA')[0]
    .impactMethodId as string;

  const { data: impactList } = useGetImpactMethodByIdQuery(
    scenarioTemplate ? { impactMethodId: impactMethodId } : skipToken,
  );

  const methodology = scenarioTemplate.assessmentConfigurations
    .filter((type) => type.assessmentType === 'LCA')
    .map((type) => type.impactMethodRefId)[0];

  const methodologiesList: string[] = [
    '2dd484cd-412a-452f-bda7-bdb28883f1ef',
    '7b539e98-a49e-4814-9149-b87e4f1a5352',
    '6977bdea-9597-4833-a4df-3d2c2d72973a',
    '8b4a0911-1fd2-40bb-a0f2-5d9975491584',
  ];

  const isMultiSelectCondition = methodologiesList.includes(methodology);

  const indicatorsOptions = useMemo(() => {
    const options = impactList
      ? impactList.impactCategories.map((impact) => ({
          value: impact.name,
          label: impact.name,
        }))
      : [];

    if (isMultiSelectCondition) {
      options.push({ value: '', label: 'All indicators' });
    }
    return options;
  }, [impactList]);

  const handleSubmitProcessHotspot = async () => {
    try {
      await handleSaveCustomization();
      const response = await handleSubmit({
        assessmentType: assessmentType,
        scenarioId: scenarioId,
        thresholdPercentage: threshold,
        indicators: selectedIndicatorOptions,
      });

      // set current hotspot with the response data
      setCurrentHotspot([...response.data.hotspots]);
      handleOpen(false);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // const assessmentOptions = useMemo(
  //   () =>
  //     scenarioTemplate
  //       ? scenarioTemplate.assessmentConfigurations.map((type) => ({
  //         value: type.assessmentType,
  //         label: type.assessmentType,
  //       }))
  //       : [],
  //   [scenarioTemplate],
  // );

  const [selectedIndicatorOptions, setSelectedIndicatorsOptions] = useState(
    isMultiSelectCondition ? [''] : indicatorsOptions?.map((option) => option.label)[0],
  );

  const handleSelectChange = (selectedOptions) => {
    if (selectedOptions.length === 0) {
      setSelectedIndicatorsOptions(['']);
    } else {
      if (isMultiSelectCondition) {
        const filteredOptions = selectedOptions.filter((option) => option !== '');
        setSelectedIndicatorsOptions(filteredOptions);
      } else {
        setSelectedIndicatorsOptions(selectedOptions);
      }
    }
  };

  const assessmentOptions = [{ value: 'LCA', label: 'LCA' }];

  const handlePopupOpen = () => {
    handleOpen(!show);
    handleHotspotMode(false);
  };

  return (
    <Modal open={show} handleOpen={handlePopupOpen} confirm={handleSubmitProcessHotspot} label={'Execute'}>
      <div className="flex-col bg-white px-4 p-10 xs:p-4">
        <div className="flex justify-center">
          <h3 className="text-xl font-semibold leading-6 mb-2 text-gray-900">Hotspot identification</h3>
        </div>

        <div className="grid grid-cols-3 gap-6 mb-2">
          <div className="col-span-1 flex items-center">
            <h3 className="text-base font-semibold leading-6 text-gray-900">Assessment type</h3>
          </div>
          <div className="col-span-2 mt-4">
            <Select
              className="basic-single"
              classNamePrefix="select"
              options={assessmentOptions}
              defaultValue={assessmentOptions[0]}
              onChange={(e) => {
                setAssessmentType(e?.value as string);
              }}
            />
          </div>
        </div>

        <div className="grid grid-cols-3 gap-6" key={'threshold'}>
          <div className="flex items-center">
            <div className="col-span-1 flex flex-col items-start">
              <h3 className="text-base font-semibold leading-6 text-gray-900">Threshold Percentage</h3>
              <p className="text-xs text-gray-500">Cumulative impact of the processes on the most impactful phase</p>
            </div>
          </div>
          <div className="col-span-2 mt-4">
            <Slider min={5} max={100} step={5} value={threshold} onChange={(val) => setThreshold(val)} />
          </div>
        </div>

        <div className="grid grid-cols-3 gap-6 mt-2">
          <div className="flex items-center">
            <div className="col-span-1 flex flex-col items-start">
              <h3 className="text-base font-semibold leading-6 text-gray-900">Impact indicators</h3>
              <p className="text-xs text-gray-500">
                Impact indicators on which to perform the algorithm, all are used by default
              </p>
            </div>
          </div>
          <div className="col-span-2 mt-4">
            <SimpleSelect
              isMulti={isMultiSelectCondition}
              description={
                isMultiSelectCondition
                  ? 'Impact indicators on which to perform the algorithm, all are used by default'
                  : 'Select a single impact indicator on which to perform the algorithm'
              }
              label={'Impact indicators'}
              options={indicatorsOptions}
              value={selectedIndicatorOptions}
              onChange={handleSelectChange}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
