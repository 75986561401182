import { HiArrowPathRoundedSquare } from 'react-icons/hi2';
import { useEffect, useMemo, useState } from 'react';
import {
  useInvokeFlowMutation,
  useInvokeIntegrationFlowMutation,
} from '../../../../api/remoteDispatcher/remoteDispatcherApi';
import { InputText } from '../../../../components/input';
import { ParameterType } from '../../../../api/customizationSpace/types/ParameterType';
import { IndexedParameter } from '../CustomizationTemplateForm';
import { RemoteParameter } from '../../../../api/customizedScenario/customizedScenarioApi';
import { MdOutlineUpdate } from 'react-icons/md';
import { Option, OptionParameter } from '../../../../api/scenarioTemplate/scenarioTemplateApi';
import { SimpleSelect } from '../../../../components/input/SimpleSelect';

type CustomOptionInputProps = OptionParameter & {
  parameters: IndexedParameter[];
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const CustomOptionInput = (props: CustomOptionInputProps) => {
  const { optionsFlowId, label, options } = props;

  const [invokeFlow] = useInvokeIntegrationFlowMutation();
  const [remoteOptions, setRemoteOptions] = useState<Option[] | undefined>([]);

  useEffect(() => {
    const getData = async () => {
      if (optionsFlowId) {
        const result = await invokeFlow({
          flowId: optionsFlowId as string,
          remotePayloadDto: {},
        });
        console.log({ result });
        if (result && !('error' in result)) {
          setRemoteOptions(result.data.data as Option[]);
        }
      } else {
        setRemoteOptions(options);
      }
    };
    getData();
  }, [optionsFlowId]);

  return <SimpleSelect {...props} options={remoteOptions ? remoteOptions : []} />;
};

export default CustomOptionInput;
