import { emptyRoleBasedAccessApi as api } from './emptyApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllPermissions: build.query<GetAllPermissionsApiResponse, GetAllPermissionsApiArg>({
      query: (queryArg) => ({
        url: `/v1/permission/`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'], 'x-role': queryArg['x-role'] },
      }),
    }),
    postPermission: build.mutation<PostPermissionApiResponse, PostPermissionApiArg>({
      query: (queryArg) => ({
        url: `/v1/permission/`,
        method: 'POST',
        body: queryArg.permission,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'], 'x-role': queryArg['x-role'] },
      }),
    }),
    deletePermission: build.mutation<DeletePermissionApiResponse, DeletePermissionApiArg>({
      query: (queryArg) => ({
        url: `/v1/permission/`,
        method: 'DELETE',
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'], 'x-role': queryArg['x-role'] },
        params: { role: queryArg.role },
      }),
    }),
    getPermissionByRole: build.query<GetPermissionByRoleApiResponse, GetPermissionByRoleApiArg>({
      query: (queryArg) => ({
        url: `/v1/permission/${queryArg.role}`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'], 'x-role': queryArg['x-role'] },
      }),
    }),
    getPermissionByRoleFromToken: build.query<
      GetPermissionByRoleFromTokenApiResponse,
      GetPermissionByRoleFromTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/permission/token`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'], 'x-role': queryArg['x-role'] },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as roleBasedAccessManagerApi };
export type GetAllPermissionsApiResponse = /** status 200 OK */ Permission[];
export type GetAllPermissionsApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  /** role */
  'x-role'?: string;
};
export type PostPermissionApiResponse = /** status 200 OK */ Permission;
export type PostPermissionApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  /** role */
  'x-role'?: string;
  permission: Permission;
};
export type DeletePermissionApiResponse = /** status 200 OK */ GenericResponse;
export type DeletePermissionApiArg = {
  role: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  /** role */
  'x-role'?: string;
};
export type GetPermissionByRoleApiResponse = /** status 200 OK */ Permission;
export type GetPermissionByRoleApiArg = {
  role: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  /** role */
  'x-role'?: string;
};
export type GetPermissionByRoleFromTokenApiResponse = /** status 200 OK */ Permission;
export type GetPermissionByRoleFromTokenApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  /** role */
  'x-role'?: string;
};
export type ObjectId = {
  timestamp?: number;
  date?: string;
};
export type Properties = {
  disabled?: boolean;
  className?: string;
  visible?: boolean;
};
export type Configuration = {
  selector?: string;
  properties?: Properties;
};
export type Permission = {
  created?: string;
  lastUpdated?: string;
  createdBy?: string;
  realm?: string;
  id?: ObjectId;
  role?: string;
  configurations?: Configuration[];
};
export type GenericResponse = {
  message?: string;
  details?: string;
  data?: object;
};
export const {
  useGetAllPermissionsQuery,
  usePostPermissionMutation,
  useDeletePermissionMutation,
  useGetPermissionByRoleQuery,
  useGetPermissionByRoleFromTokenQuery,
} = injectedRtkApi;
