import React, { useEffect, useState } from 'react';
import { Link } from '../../../components/Elements';
import { useBreadcrumbs } from '../../../hooks/breadcrumbs';
import ListProject from '../components/ListProject';
import { InputText } from '../../../components/input';
import { HiMagnifyingGlass, HiPlusCircle } from 'react-icons/hi2';
import { RiDeleteBinLine, RiEditBoxLine } from 'react-icons/ri';
import { Roled } from '../../roles/hooks/Roled';
import { CreateProjectButton } from '../components/CreateProjectButton';

export const Projects = () => {
  const [search, setSearch] = useState<string>();
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([{ label: 'projects' }]);
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row ml-auto my-3">
        <InputText
          addonBefore={<HiMagnifyingGlass className="w-6 h-6 text-black" />}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <Roled name={'create-project'}>
        <CreateProjectButton to={'./new'} className={'relative grid grid-cols-12'} />
      </Roled>
      <ListProject filter={{ projectName: search, projectDescription: search }} />
    </div>
  );
};
