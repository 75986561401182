import React, { useEffect } from 'react';
import { Link } from '../../../components/Elements';
import { useBreadcrumbs } from '../../../hooks/breadcrumbs';
import ListTemplate from '../components/ListTemplate';
import { HiPlusCircle } from 'react-icons/hi2';
import { RiDeleteBinLine } from 'react-icons/ri';

export const Templates = ({ type }: { type?: 'BOM' | 'BASIC' }) => {
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([{ label: 'templates' }]);
  }, []);

  return (
    <div className="flex flex-col mt-3">
      {type ? (
        /*<div className="mt-3">
            <Link to={`/app/templates/${type}/new`}>
              <Button className={'float-right'} style={{ height: '46px' }} variant="inverse" size={'md'}>
                <HiPlusCircle className="mr-2 h-6 w-6" /> Create New
              </Button>
            </Link>
          </div>
*/

        <div className="relative grid grid-cols-12 flex border border-gray-400 border-dashed border-spacing-2">
          <Link
            to={`/app/templates/${type}/new`}
            /*className={'absolute flex w-full h-full text-gray-600 cursor-pointer content-center items-center text-center backdrop-blur-[4px] hover:bg-blue-100 hover:bg-opacity-80 hover:text-blue-600 hover:border-blue-400'}>*/
            className={
              'absolute flex w-full h-full text-gray-600 cursor-pointer bg-opacity-60 bg-white content-center items-center text-center hover:bg-blue-100 hover:bg-opacity-90 hover:text-blue-600 hover:border-blue-400'
            }
          >
            <div className="absolute left-[40%]">
              <HiPlusCircle className="h-6 w-6 mr-2 float-left" /> Create New Scenario Template
            </div>
          </Link>

          <div className="px-4 py-4 sm:px-6 col-span-10 bg-white">
            <div className="flex items-center justify-between">
              <p className="truncate text-sm font-medium text-blue-600">template name</p>
            </div>
            <div className="mt-2 sm:flex sm:justify-between">
              <div className="sm:flex">
                <p className="flex items-center text-sm text-gray-500">template description</p>
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                <p>{new Date().toDateString()}</p>
              </div>
            </div>
          </div>
          <div
            className={
              'justify-center flex col-span-2 items-center hover:bg-red-100 border-0 text-red-500 rounded-none bg-white'
            }
          >
            <RiDeleteBinLine className="h-6 w-6" />
          </div>
        </div>
      ) : null}
      <ListTemplate type={type} />
    </div>
  );
};
