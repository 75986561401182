import { Menu, Transition } from '@headlessui/react';
import { HiBars3BottomLeft } from 'react-icons/hi2';
import { Fragment, ReactNode } from 'react';
import classNames from '../../utils/classNames';
import { IconType } from 'react-icons';
import Item from './Item';
import { menuElements } from './index';

export type MenuItem = {
  name: string | undefined;
  description: string | undefined;
  onClick?: () => void;
  Icon: IconType;
  disabled?: boolean;
  className?: string;
  type?: 'MenuItem' | string;
};

export type SeparatorItem = {
  title: string | ReactNode;
  className?: string;
  type: 'SeparatorItem' | string;
};

type ActionMenuProps = {
  className?: string;
  onClick?: () => void;
  items: (MenuItem | SeparatorItem)[];
};

export default function ActionMenu(props: ActionMenuProps) {
  const { className, items, onClick } = props;

  return (
    <Fragment>
      <Menu as={'div'} className={classNames(className ? className : '', 'h-8')}>
        <Menu.Button type="button" className="rounded focus:outline-none" onClick={onClick}>
          <span className="sr-only">Open sidebar</span>
          <HiBars3BottomLeft
            className="text-blue-700 bg-blue-100 h-8 w-8 p-1 rounded hover:bg-blue-200"
            aria-hidden="true"
          />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute -translate-x-[88%] w-72 origin-bottom rounded bg-white focus:outline-none border-2 z-50">
            <div className="py-1 z-50">
              {items.map((item) => {
                const Element = item.type ? menuElements[item.type] : Item;
                return <Element {...item} />;
              })}
            </div>
          </Menu.Items>
          {/*<Menu.Items className="absolute -translate-x-[88%] w-72 origin-bottom rounded bg-white focus:outline-none border-2 z-50">*/}
          {/*  <div className="py-1 z-50">*/}
          {/*    {items.map((item) => (*/}
          {/*      <Menu.Item key={item.name}>*/}
          {/*        {({ active }) => (*/}
          {/*          <div*/}
          {/*            className={classNames(*/}
          {/*              'group relative flex gap-x-5 rounded p-3 text-sm z-50',*/}
          {/*              item.disabled ? 'cursor-not-allowed opacity-30 bg-gray-200' : 'cursor-pointer hover:bg-gray-50',*/}
          {/*            )}*/}
          {/*          >*/}
          {/*            <div className="mt-1 flex h-8 w-8 flex-none items-center justify-center rounded">*/}
          {/*              {item.Icon && (*/}
          {/*                <item.Icon className="h-6 w-6 text-gray-600 group-hover:text-blue-600" aria-hidden="true" />*/}
          {/*              )}*/}
          {/*            </div>*/}
          {/*            <div>*/}
          {/*              <div*/}
          {/*                onClick={!item.disabled ? item.onClick : undefined}*/}
          {/*                className="font-semibold text-gray-900"*/}
          {/*              >*/}
          {/*                {item.name}*/}
          {/*                <span className="absolute inset-0" />*/}
          {/*              </div>*/}
          {/*              <p className="text-xs text-gray-500">{item.description}</p>*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*        )}*/}
          {/*      </Menu.Item>*/}
          {/*    ))}*/}
          {/*  </div>*/}
          {/*</Menu.Items>*/}
        </Transition>
      </Menu>
    </Fragment>
  );
}
