import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Bom, useGetBomQuery } from '../../../../api/bom/bomApi';
import BillOfMaterial from '../../../bom-tree/components/BillOfMaterial';
import {
  Commit,
  ItemScenarioTemplatePair,
  usePostCommitMutation,
  usePostScenarioTemplateMutation,
} from '../../../../api/scenarioTemplate/scenarioTemplateApi';
import React, { useEffect, useState } from 'react';
import { useStateMachine } from 'little-state-machine';
import initScenarioTemplateAction from '../../utils/initScenarioTemplateAction';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import VariableConfigurationsManager from './VariableConfigurationsManager';
import ButModal from '../../../../components/Modal/ButModal';
import ParameterSection from '../BasicScenarioTemplate/ParameterSection';
import { Button } from '../../../../components/Elements';
import { toast } from 'react-hot-toast/headless';
import { yupResolver } from '@hookform/resolvers/yup';
import { bomScenarioTemplateSchema } from '../validation/bomScenarioTemplateSchema';
import { HiOutlineEye } from 'react-icons/hi2';
import { getParameterErrorsForItem } from '../../../comparison/utils/getParameterErrorsForItem';
import { Tooltip } from 'react-tooltip';
import ButSlidePanel from '../../../../components/SlidePanel/ButSlidePanel';
import { FiGitCommit } from 'react-icons/fi';
import CommitList from '../CommitList';
import ButSaveModal from '../ButSaveModal';
import {
  useCreateCombinationTreeV1SmosCreateCombinationTreeTemplateIdPostMutation,
  useHasCombinationTreeV1SmosHasCombinationTreeTemplateIdGetQuery,
} from '../../../../api/advisory/advisoryApi';
import { useGetCustomizationSpacesQuery } from '../../../../api/customizationSpace/customizationSpaceApi';

function BomScenarioTemplateForm() {
  const navigate = useNavigate();
  const { state, actions } = useStateMachine({ initScenarioTemplateAction });

  const [saveWithErrorModal, setSaveWithErrorModal] = useState(false);

  const [postScenarioTemplate] = usePostScenarioTemplateMutation();
  const [postCommit] = usePostCommitMutation();
  const { data: bom } = useGetBomQuery(state.template ? { bomId: state.template.bomId } : skipToken);

  const [postedMetaSpace, setPostedMetaSpace] = useState(false);
  const [postMetaSpace] = useCreateCombinationTreeV1SmosCreateCombinationTreeTemplateIdPostMutation();
  const { data: customizationSpaces } = useGetCustomizationSpacesQuery({
    customizationSpaceRefIds: undefined,
  });

  const handleBack = () => {
    actions.initScenarioTemplateAction({ template: getValues() });
    navigate(-1);
  };

  const methods = useForm({
    defaultValues: { ...state.template },
    resolver: (values, context, options) => {
      return yupResolver(bomScenarioTemplateSchema)(
        values,
        {
          ...values,
        },
        options,
      );
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const {
    setValue,
    trigger,
    control,
    formState: { errors },
    watch,
    getValues,
  } = methods;

  useEffect(() => {
    if (!state.template) {
      handleBack();
    } else {
      trigger();
    }
  }, []);

  const { data: hasCombinationTree, error: hasCombinationTreeError } =
    useHasCombinationTreeV1SmosHasCombinationTreeTemplateIdGetQuery(
      postedMetaSpace ? { templateId: getValues().id as string } : skipToken,
    );

  const handleFormSubmit = async (values: any, valid: boolean, commit: Commit) => {
    console.log('Template', { values });
    values.valid = valid;
    const scenarioTemplate: any = await postScenarioTemplate({ body: values });
    if (scenarioTemplate.error) {
      console.log(scenarioTemplate.error);
      toast.error('Failed to create scenario template');
    } else {
      postCommit({
        commit: {
          ...commit,
          message: `${commit?.message}${!valid ? ' [saved with error]' : ''}`,
          scenarioTemplateId: scenarioTemplate.data.id,
        } as Commit,
      });
      if (!values.id) {
        setValue('id', scenarioTemplate.id);
      }
      toast.success('Scenario template created successfully');
    }

    const metaSpace = await postMetaSpace({ templateId: scenarioTemplate.data.id as string });
    if (metaSpace.error) {
      console.log(metaSpace.error);
      toast.error('Failed to create metaspace');
    } else {
      setPostedMetaSpace(true);
    }

    // // check if the metaspace combinations exist
    // if (!hasCombinationTree) {
    //   toast.error('Cannot optimize this scenario template');
    // }
    toast.success('Metaspace combinations created successfully');
  };

  const handleSaveWithError = async (errors) => {
    setSaveWithErrorModal(true);
    console.warn(errors);
  };

  const handleTrigger = async () => {
    const result = await trigger();
    if (result) {
      toast.success('Scenario template is valid');
    } else {
      console.error({ errors });
      toast.error('Scenario template has errors');
    }
  };

  const template = watch();
  const parameters = watch('parameters');

  const fieldsMethod = useFieldArray({
    control,
    name: 'parameters',
  });

  return (
    <FormProvider {...methods}>
      <div className="shadow rounded bg-white p-4 h-full mt-3">
        <form>
          <div className="absolute right-10 mt-2 z-20 ">
            <HiOutlineEye className={'h-8 w-8 cursor-pointer'} onClick={handleTrigger} />
          </div>
          <Tooltip variant={'info'} anchorSelect={'#info'} content="Commits" />
          {template?.id && (
            <ButSlidePanel
              className={'absolute right-16 mr-2 mt-2 z-20'}
              id={'info'}
              buttonSize={'none'}
              buttonLabel={<FiGitCommit className={'h-8 w-8 cursor-pointer mr-2'} />}
              label={'Changes on the scenario template'}
            >
              <CommitList scenarioTemplateId={template?.id} />
            </ButSlidePanel>
          )}
          <BillOfMaterial
            className={'border-gray-200 border-2'}
            bom={bom as Bom}
            nodeConfiguration={{
              nodeContent: (item) => {
                let config = template.itemScenarioTemplateConfiguration?.find(
                  (i: ItemScenarioTemplatePair) => i.parentId && i.itemId === item.id && i.parentId === item.parentId,
                );

                if (!config) {
                  config = template.itemScenarioTemplateConfiguration?.find(
                    (i: ItemScenarioTemplatePair) => !i.parentId && i.itemId === item.id,
                  );
                }

                if (!config) {
                  return `${item.description}:${item.id}`;
                } else {
                  //Checking for errors to give an indication to users
                  const itemParameterErrors = getParameterErrorsForItem(
                    errors,
                    parameters,
                    `:${config.parentId ? config.parentId + ':' : ''}${config.itemId}`,
                  );
                  return (
                    <span
                      className={
                        itemParameterErrors?.length > 0
                          ? 'text-red-600'
                          : config.parentId
                            ? 'text-blue-600'
                            : 'text-green-600'
                      }
                    >
                      {item.description}:{item.id}
                    </span>
                  );
                }
              },
            }}
            itemContent={(item, selectedItemId) => {
              //Searching first for an individual config (for the specific item)
              let config = template.itemScenarioTemplateConfiguration?.find(
                (i) => i.itemId === item.id && i.parentId === item.parentId,
              );
              if (!config) {
                //if an individual config does not exist I search for a specific one
                config = template.itemScenarioTemplateConfiguration?.find((i) => i.itemId === item.id && !i.parentId);
              }

              if (config) {
                const itemParameters = parameters
                  .map((p, index) => ({ ...p, index }))
                  .filter(
                    (p) => p.display?.item === `:${config.parentId ? `${config.parentId}:` : ''}${config.itemId}`,
                  );

                const itemIdentifier = `:${config.parentId ? config.parentId + ':' : ''}${item.id}`;

                return (
                  <div className={'flex flex-col p-2'}>
                    <h3 className="text-center text-lg font-bold mb-6">
                      {item.description} ({config.parentId}:{config.itemId})
                    </h3>
                    <ButModal label={'Variable configuration'} className="my-2 flex-1" variant={'inverse'}>
                      <VariableConfigurationsManager
                        prefix={`itemScenarioTemplateConfiguration.${config.index}.variableConfigurations`}
                        parameters={template.parameters}
                      />
                    </ButModal>
                    <ButModal label={'Parameters Management'} className="my-2" variant={'inverse'}>
                      <div className="flex-1 p-2 gap-2 my-2">
                        {['AMOUNT_CHANGE', 'ADDITIONAL_PARAMETER', 'FLOW_SWITCH'].map((type) => {
                          return (
                            <ParameterSection
                              {...fieldsMethod}
                              key={type}
                              type={type as 'AMOUNT_CHANGE' | 'FLOW_SWITCH' | 'ADDITIONAL_PARAMETER'}
                              parameters={itemParameters}
                              item={{
                                parameterName: '',
                                type,
                                display: {
                                  item: itemIdentifier,
                                },
                                parameterType: type === 'FLOW_SWITCH' ? 'OPTION' : 'NUMERIC',
                              }}
                              name={type}
                              displayConfigurations={config.displayConfigurations}
                            />
                          );
                        })}
                      </div>
                    </ButModal>
                  </div>
                );
              }
            }}
          />
          <ButSaveModal
            variant={'primary'}
            size={'md'}
            className={'mt-3 float-right'}
            buttonLabel={'Save'}
            onFormSubmit={handleFormSubmit}
            scenarioTemplateId={state.template?.id}
          />
          <Button className={'float-right mt-3 mr-3'} onClick={handleBack}>
            Back
          </Button>
        </form>
      </div>
    </FormProvider>
  );
}

export default BomScenarioTemplateForm;
