import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ROLE_BASED_ACCESS_MANAGER_API_URL } from '../../config';
import { baseQueryWithReauth } from '../utility/queryUtility';
import storage from '../../utils/storage';

const customBaseQuery = fetchBaseQuery({
  baseUrl: ROLE_BASED_ACCESS_MANAGER_API_URL,
  // mode: 'no-cors',
  prepareHeaders: (headers, { getState }) => {
    const token = storage.getToken();

    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptyRoleBasedAccessApi = createApi({
  reducerPath: 'roleBasedAccessManager',
  baseQuery: (args, api, extraOptions) => {
    return baseQueryWithReauth(args, customBaseQuery, api, extraOptions);
  },
  endpoints: () => ({}),
});
//https://redux-toolkit.js.org/rtk-query/usage/code-generation
// npx @rtk-query/codegen-openapi openapi-rolebasedaccessmanager-config.json
