import { FormProps } from '../../../components/types';
import React from 'react';
import * as Yup from 'yup';
import { InputText, SelectControl } from '../../../components/input';
import { InputList } from '../../../components/input/InputList';
import { RiDeleteBinLine, RiEditBoxLine } from 'react-icons/ri';
import { Button } from '../../../components/Elements';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SimpleSelect } from '../../../components/input/SimpleSelect';
import ButModal from '../../../components/Modal/ButModal';

type PermissionList = {
  className?: string;
};

type PermissionFormProps = FormProps & PermissionList;

export const PermissionForm = (props: PermissionFormProps) => {
  const { onSubmit, onError, initialValues, submitLabel, className } = props;

  const permissionValidation = Yup.object().shape({
    configurations: Yup.array().of(
      Yup.object().shape({
        selector: Yup.string().required('Selector is required').nullable(),
      }),
    ),
  });

  console.log('PERMISSION FORM', initialValues);

  const { register, control, handleSubmit } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(permissionValidation),
  });

  const selectorOptions = [
    { label: 'input', value: 'input' },
    { label: 'create-scenario', value: 'create-scenario' },
    { label: 'edit-scenario', value: 'edit-scenario' },
    { label: 'delete-scenario', value: 'delete-scenario' },
    { label: 'duplicate-scenario', value: 'duplicate-scenario' },
    { label: 'create-project', value: 'create-project' },
    { label: 'edit-project', value: 'edit-project' },
    { label: 'delete-project', value: 'delete-project' },
  ];

  return (
    <form className={'shadow rounded bg-white p-4 mt-3'} onSubmit={handleSubmit(onSubmit, console.error)}>
      <InputText className={'my-3 mx-2'} {...register('role')} label="Role" />
      <InputList
        control={control}
        name={'configurations'}
        className={'my-3'}
        label={'Configuration Items'}
        item={{ type: '' }}
        renderItem={(field, index, remove, append) => {
          return (
            <div className={'col-span-12 mt-4 flex gap-x-3'} style={{ display: 'flex' }}>
              <SelectControl
                name={`configurations.${index}.selector`}
                options={selectorOptions}
                className={'my-3 flex-1'}
                control={control}
                label="Selector Name"
              />
              <InputText
                className={'my-3 flex-1'}
                {...register(`configurations.${index}.properties.className`)}
                label="ClassName"
              />
              <InputText
                className={'my-3 flex-1'}
                {...register(`configurations.${index}.properties.disabled`)}
                label="Disabled"
              />
              <InputText
                className={'my-3 flex-1'}
                {...register(`configurations.${index}.properties.visible`)}
                label="Visible"
              />
              <ButModal
                label={<RiDeleteBinLine className="h-6 w-6 mx-4" />}
                submitLabel={'Yes'}
                confirm={() => remove(index)}
                className={'items-center hover:bg-red-100 border-0 text-red-500 rounded-none focus:outline-none'}
              >
                <h2 className="text-xl text-gray-700 mb-4 mt-1 px-4 text-center">
                  Are you sure you want to delete the selector ?
                </h2>
              </ButModal>
            </div>
          );
        }}
      />

      <Button variant={'inverse'} type="submit" className={'float-right'}>
        <RiEditBoxLine className="h-6 w-6 mr-2" /> {submitLabel ? submitLabel : 'Create'}
      </Button>
    </form>
  );
};
