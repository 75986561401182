import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from '../../../components/Elements';
import { useBreadcrumbs } from '../../../hooks/breadcrumbs';
import { toast } from 'react-hot-toast/headless';
import { ReportForm } from './ReportForm';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ScenarioTemplate, useGetAllScenarioTemplateQuery } from '../../../api/scenarioTemplate/scenarioTemplateApi';
import { useGetReportQuery, usePostReportTemplateMutation } from '../../../api/report/reportApi';

function Report() {
  const { reportId } = useParams();

  const { data: report, isLoading: isReportLoading } = useGetReportQuery(reportId ? { reportId } : skipToken);
  const { data: scenarioTemplates, isLoading: areScenarioTemplatesLoading } = useGetAllScenarioTemplateQuery({});
  const [postReportTemplate] = usePostReportTemplateMutation();

  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([{ label: 'reports', path: '/app/reports' }, { label: `${report?.name}` }]);
  }, [report]);

  const onSubmit = async (reportTemplate: any, files: FileList | undefined) => {
    const formData = new FormData();
    console.log({ reportTemplate }, files);
    if (files) {
      formData.append(`reportTemplateFile`, files[0] as File);
    }
    formData.append(`reportTemplateString`, JSON.stringify(reportTemplate));
    const updatedReport: any = await postReportTemplate({
      body: formData,
    }).unwrap();
    if (updatedReport.error) {
      // console.log(updatedScenario.error);
      toast.error('Failed to update scenario');
    } else {
      toast.success('Scenario updated successfully');
    }
  };

  if (isReportLoading || areScenarioTemplatesLoading) {
    return <Spinner size={'h-14 w-14'} className="w-12 h-12 self-center m-5" />;
  }

  console.log({ report });

  return (
    <ReportForm
      onSubmit={onSubmit}
      initialValues={report}
      submitLabel={'Update'}
      scenarioTemplates={scenarioTemplates as ScenarioTemplate[]}
    />
  );
}

export default Report;
