import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  useCalculateLinkedCombinationsV1SmosCalculateLinkedCombinationsPostMutation,
  useGetLinkedParametersV1SmosLinkedParametersGetQuery,
  useHasCombinationTreeV1SmosHasCombinationTreeTemplateIdGetQuery,
} from '../../../api/advisory/advisoryApi';
import { skipToken } from '@reduxjs/toolkit/query';
import { ParameterType } from '../../../api/customizationSpace/types/ParameterType';
import toast from 'react-hot-toast/headless';

type ContextProps = {
  templateIdentifier: string | undefined;
  identifier: string;
  phases: string[];
  displayErrors: boolean;
  phaseRendered: (phaseId: string) => void;
  setDisplayErrors: (displayStatus: boolean) => void;
  advisory: boolean;
  combinations: {};
  setCombinations: (a: any) => void;
  hotspot: boolean;
  setHotspot: (hotspot: boolean) => void;
  showHotspotPopup: boolean;
  setShowHotspotPopup: (showHotspotPopup: boolean) => void;
  currentHotspot: string[];
  setCurrentHotspot: (a: any) => void;
  handleHotspotMode: (scenarioErrors, existAssessmentType: boolean | undefined) => void;
  handleAdvisoryMode: (scenarioErrors, existAssessmentType: boolean | undefined) => void;
  handleAdvisoryMode: (scenarioErrors, existAssessmentType: boolean | undefined) => void;
  linkedParameters: string[];
  categoricalParameters: string[];
  updateCombinations: (
    parameters,
    parameterType,
    parameterName,
    minValue,
    maxValue,
    step,
    optionsList,
    isStatic: boolean,
  ) => void;
  scenarioId: string | undefined;
  setScenarioId: (scenarioId: string) => void;
  projectId: string | undefined;
  setProjectId: (scenarioId: string) => void;
  hasCombinationTree: boolean | null;
  useHasCombinationTree: (templateId: string) => boolean | null;
};

const CustomizationTemplateContext = React.createContext<ContextProps>({
  templateIdentifier: undefined,
  identifier: '',
  phaseRendered: () => {},
  phases: [],
  displayErrors: false,
  setDisplayErrors: () => {},
  advisory: false,
  combinations: {},
  setCombinations: () => null,
  hotspot: false,
  setHotspot: () => null,
  showHotspotPopup: false,
  setShowHotspotPopup: () => null,
  currentHotspot: [],
  setCurrentHotspot: () => null,
  handleHotspotMode: () => null,
  handleAdvisoryMode: () => null,
  linkedParameters: [],
  categoricalParameters: [],
  updateCombinations: () => {},
  scenarioId: '',
  setScenarioId: () => null,
  projectId: '',
  setProjectId: () => null,
  hasCombinationTree: null,
  useHasCombinationTree: () => false,
});

export const CustomizationTemplateProvider = ({
  initialTemplateIdentifier = undefined,
  identifier,
  children,
}: {
  initialTemplateIdentifier?: string;
  identifier: string;
  children: any;
}) => {
  const [templateIdentifier] = useState(initialTemplateIdentifier);
  const [formIdentifier, setFormIdentifier] = useState(identifier);
  const [advisory, setAdvisory] = useState(false);
  const [combinations, setCombinations] = useState({});
  const [hotspot, setHotspot] = useState(false);
  const [currentHotspot, setCurrentHotspot] = useState<string[]>([]);
  const [phases, setPhases] = useState<string[]>([]);
  const [displayErrors, setDisplayErrors] = useState(false);
  const [scenarioId, setScenarioId] = useState('');
  const [projectId, setProjectId] = useState('');
  const [parameters, setParameters] = useState({});
  const [categoricalParameters, setCategoricalParameters] = useState<string[]>([]);
  const [showHotspotPopup, setShowHotspotPopup] = useState<boolean>(false);
  const [hasCombinationTree, setHasCombinationTree] = useState<boolean | null>(null);

  function useHasCombinationTree(templateId: string) {
    const { data, error } = useHasCombinationTreeV1SmosHasCombinationTreeTemplateIdGetQuery(
      { templateId },
      { skip: !templateId },
    );

    useEffect(() => {
      if (!templateId) {
        console.error('templateId is required');
        return;
      }

      if (error) {
        console.error('Error fetching metaspace combinations:', error);
        setHasCombinationTree(false);
      } else if (data !== undefined) {
        setHasCombinationTree(data as boolean);
      }
    }, [templateId, data, error]);

    return hasCombinationTree;
  }

  const phaseRendered = (phaseId: string) => {
    if (phases.indexOf(phaseId) === -1) {
      setPhases((prevValue) => [...prevValue, phaseId]);
    }
  };

  const [calculateLinkedCombinations] = useCalculateLinkedCombinationsV1SmosCalculateLinkedCombinationsPostMutation();

  // fetch linked combinations of the scenario
  const { data: linkedCombinationsData, error: linkedCombinationsError } =
    useGetLinkedParametersV1SmosLinkedParametersGetQuery(scenarioId ? { scenarioId } : skipToken);

  // list of linked parameters of the scenario
  const linkedParameters = useMemo(
    () => (linkedCombinationsData ? linkedCombinationsData.parameters : []),
    [linkedCombinationsData],
  );

  const calculateNumericCombination = (parameter) => {
    let numberOfCombinations = 1;
    if (Number.isInteger(parameter)) {
      numberOfCombinations = parameter + 1;
    } else {
      numberOfCombinations = Math.ceil(parameter);
    }

    if (numberOfCombinations <= 0 || isNaN(numberOfCombinations)) {
      numberOfCombinations = 1;
    }
    return numberOfCombinations;
  };

  const updateCombinations = async (
    parameters,
    parameterType,
    parameterName,
    minValue,
    maxValue,
    step,
    optionsList,
    isStatic,
  ) => {
    setParameters(parameters);

    let numberOfCombinations: any = 1;
    if (!linkedParameters.includes(parameterName)) {
      if (parameterType === ParameterType[ParameterType.NUMERIC] && !isStatic) {
        const result = (maxValue - minValue) / step;
        numberOfCombinations = calculateNumericCombination(result);
      } else if (parameterType === ParameterType[ParameterType.OPTION] && optionsList && !isStatic) {
        numberOfCombinations = optionsList.length;
        setCategoricalParameters((prev) => [...prev, parameterName]);
      }
    } else {
      // if (optionsList || parameterType === ParameterType[ParameterType.NUMERIC]) {
      const fetchedNumberOfCombinations = await calculateLinkedCombinations({
        body: parameters,
        scenarioId,
      });
      parameterName = 'linkedParameters';
      if (fetchedNumberOfCombinations.error) {
        // if it cannot fetch the number of linked combinations, set it to 0
        numberOfCombinations = 0;
      } else {
        // set the number of linked combinations
        numberOfCombinations = fetchedNumberOfCombinations?.data.combinations;
      }
    }

    setCombinations((prevCombination) => ({
      ...prevCombination,
      [parameterName]: numberOfCombinations,
    }));
  };

  const handleHotspotMode = (scenarioErrors, existAssessmentType) => {
    if (scenarioErrors && !hotspot && !advisory) {
      toast.error('Enter all parameters to enable hotspot identification');
      return;
    } else if (!existAssessmentType) {
      toast.error('No LCA assessment type available');
      return;
    }
    setHotspot(!hotspot);
    setShowHotspotPopup(!hotspot);
    // if hotspot is disabled, clear the current hotspot
    if (!hotspot) setCurrentHotspot([]);
  };

  const handleAdvisoryMode = (scenarioErrors, existAssessmentType) => {
    setAdvisory(!advisory);

    // if hotspot is enabled, don't do anything
    if (hotspot) {
      return;
    }

    if (existAssessmentType) {
      handleHotspotMode(scenarioErrors, existAssessmentType);
    }
  };

  return (
    <CustomizationTemplateContext.Provider
      value={{
        templateIdentifier,
        phases,
        displayErrors,
        setDisplayErrors,
        phaseRendered,
        advisory,
        combinations,
        setCombinations,
        hotspot,
        setHotspot,
        showHotspotPopup,
        setShowHotspotPopup,
        currentHotspot,
        setCurrentHotspot,
        handleHotspotMode,
        handleAdvisoryMode,
        linkedParameters,
        categoricalParameters,
        updateCombinations,
        scenarioId,
        setScenarioId,
        projectId,
        setProjectId,
        identifier: formIdentifier,
        hasCombinationTree,
        useHasCombinationTree,
      }}
    >
      {children}
    </CustomizationTemplateContext.Provider>
  );
};

export const useCustomizationTemplateContext = () => {
  const {
    templateIdentifier,
    identifier,
    phases,
    displayErrors,
    setDisplayErrors,
    phaseRendered,
    advisory,
    combinations,
    setCombinations,
    hotspot,
    setHotspot,
    showHotspotPopup,
    setShowHotspotPopup,
    currentHotspot,
    setCurrentHotspot,
    handleHotspotMode,
    handleAdvisoryMode,
    linkedParameters,
    categoricalParameters,
    updateCombinations,
    scenarioId,
    setScenarioId,
    projectId,
    setProjectId,
    useHasCombinationTree,
    hasCombinationTree,
  } = useContext(CustomizationTemplateContext);
  return {
    templateIdentifier,
    identifier,
    phases,
    displayErrors,
    setDisplayErrors,
    phaseRendered,
    advisory,
    combinations,
    setCombinations,
    hotspot,
    setHotspot,
    showHotspotPopup,
    setShowHotspotPopup,
    currentHotspot,
    setCurrentHotspot,
    handleHotspotMode,
    handleAdvisoryMode,
    linkedParameters,
    categoricalParameters,
    updateCombinations,
    scenarioId,
    setScenarioId,
    projectId,
    setProjectId,
    hasCombinationTree,
    useHasCombinationTree,
  };
};
