import { PermissionForm } from './PermissionForm';
import { toast } from 'react-hot-toast/headless';
import { Permission, usePostPermissionMutation } from '../../../api/roleBasedAccessManager/roleBasedAccessManager';

function CreatePermission() {
  const [postPermission] = usePostPermissionMutation();

  const onSubmit = async (permission: Permission) => {
    console.log('permission', permission);
    const newPermission: any = await postPermission({
      permission: permission,
    }).unwrap();
    if ('error' in newPermission) {
      toast.error('Failed to create permission');
    } else {
      toast.success('permission created successfully');
    }
  };

  return <PermissionForm className={'mt-2'} onSubmit={onSubmit} formMode={'CREATE'} />;
}

export default CreatePermission;
