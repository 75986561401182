import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ProjectDto,
  useGetAllScenarioQuery,
  useGetProjectQuery,
  usePostProjectMutation,
} from '../../../api/project/projectApi';
import { Link, Spinner } from '../../../components/Elements';
import { useBreadcrumbs } from '../../../hooks/breadcrumbs';
import ListScenario from '../../scenario/components/ListScenario';
import { ProjectForm } from './ProjectForm';
import { toast } from 'react-hot-toast/headless';
import { InputText } from '../../../components/input';
import { HiMagnifyingGlass, HiPlusCircle } from 'react-icons/hi2';
import { LiaCubeSolid } from 'react-icons/lia';
import { RiDeleteBinLine, RiEditBoxLine } from 'react-icons/ri';
import { GrDuplicate } from 'react-icons/gr';
import { Roled } from '../../roles/hooks/Roled';
import { CreateScenarioButton } from '../../scenario/components/CreateScenarioButton';

function Project() {
  const { projectId } = useParams();
  const [search, setSearch] = useState();
  const { data: project, isLoading } = useGetProjectQuery(projectId ? { projectId: projectId } : skipToken);
  const [postProject] = usePostProjectMutation();
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    if (project) {
      setBreadcrumbs([
        { label: 'projects', path: '/app/projects' },
        { label: project.name as string, path: `/app/projects/${projectId}` },
      ]);
    }
  }, [project]);

  const onSubmit = async (project: ProjectDto) => {
    const updatedProject: any = await postProject({
      projectDto: project,
    }).unwrap();
    if (updatedProject.error) {
      // console.log(updatedProject.error);
      toast.error('Failed to update project');
    } else {
      toast.success('Project updated successfully');
    }
  };

  if (isLoading) {
    return <Spinner size={'h-14 w-14'} className="w-12 h-12 self-center m-5" />;
  }

  return (
    <>
      {project && (
        <Roled name={'create-project'}>
          <ProjectForm className="mt-3" initialValues={project} onSubmit={onSubmit} submitLabel={'Update'} />
        </Roled>
      )}

      <div className="flex flex-col">
        <div className="flex flex-row ml-auto my-3">
          <InputText
            addonBefore={<HiMagnifyingGlass className="w-6 h-6 text-black" />}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <Roled name={'create-scenario'}>
          <CreateScenarioButton className={'relative grid grid-cols-12'} to={`/app/scenarios/new/${projectId}`} />
        </Roled>

        <ListScenario
          filter={projectId ? { projectId: projectId, scenarioName: search, scenarioDescription: search } : skipToken}
          fetchData={useGetAllScenarioQuery}
        />
      </div>
    </>
  );
}

export default Project;
