import React, { useEffect } from 'react';
import '../style/style.css';
import { DisplayConfiguration, OptionParameter } from '../../../api/scenarioTemplate/scenarioTemplateApi';
import classNames from '../../../utils/classNames';
import { useFormContext } from 'react-hook-form';
import { useCustomizationTemplateContext } from '../hooks/CustomizationTemplateProvider';
import { DefaultProps } from '../../../components/carousel/Carousel';
import useRenderCounter from '../../../hooks/useRenderCounter';
import CustomizationPhaseForm from './CustomizationPhaseForm';
import { findParameter } from '../utils/findParameter';
import { BiSolidError } from 'react-icons/bi';

import {
  FlowSwitchParameter,
  FormulaParameter,
  RemoteParameter,
} from '../../../api/customizedScenario/customizedScenarioApi';
import { HiMiniFire } from 'react-icons/hi2';
import { isNumber } from 'lodash';

export type IndexedParameter = (RemoteParameter | FlowSwitchParameter | FormulaParameter | OptionParameter) & {
  index: number;
  hidden: string | boolean | undefined;
};

type CustomizationTemplateFormProps = {
  displayConfigurations: DisplayConfiguration[];
  parameters: IndexedParameter[];
  currentPhaseId: string | undefined;
  changePhase: (newPhaseId: string) => void;
  onFormChange: () => void;
  fromComparison: boolean;
} & DefaultProps;

export default function CustomizationTemplateForm(props: CustomizationTemplateFormProps) {
  const { displayConfigurations, parameters, currentPhaseId, className, changePhase, onFormChange, fromComparison } =
    props;

  const renderCounter = useRenderCounter();

  const {
    formState: { errors },
    setFocus,
  } = useFormContext();
  const { trigger } = useFormContext();

  const { phases: phaseCount, setDisplayErrors } = useCustomizationTemplateContext();
  const { hotspot, currentHotspot } = useCustomizationTemplateContext();

  useEffect(() => {
    if (typeof currentPhaseId === 'undefined') {
      changePhase(displayConfigurations?.[0].phaseId as string);
    }
  }, []);

  const validate = async (currentTab: string, tabCallBack: (currentTab: string) => void) => {
    await trigger();
    console.log('Setting validation');
    setDisplayErrors(true);
    if (Object.keys(errors).length > 0) {
      //Removing the empty one (no error)
      const parameterIndexes = errors.parameters?.map((ep, index) => (ep ? index : undefined)).filter(isNumber);
      //Search a parameter in error in the current displayed phase
      // otherwise we move to a different tab
      const errorParameters = parameters.filter((p) => parameterIndexes.includes(p.index));
      let parameter = findParameter(errorParameters, currentPhaseId, currentTab);
      if (parameter) {
        const { display } = parameter;

        let targetParameter = display?.formulaParameter
          ? parameters.find((ep) => ep.parameterName === display?.formulaParameter)
          : parameter;

        if (targetParameter?.display?.phaseId === currentPhaseId) {
          if (targetParameter?.display?.tab) {
            tabCallBack(targetParameter?.display.tab as string);
          }
        } else if (targetParameter?.display?.phaseId) {
          changePhase(targetParameter?.display.phaseId as string);
        }

        setFocus(`parameters.${parameter.index}.value`);
      }
    }
  };

  return (
    <div
      className={classNames(
        className ? className : '',
        !fromComparison ? 'customization-view' : '',
        'pb-7 bg-white min-h-[100%]',
      )}
    >
      {renderCounter}
      <ol className="flex flex-nowrap bg-white overflow-x-auto">
        {displayConfigurations?.map((pc, stepIdx) => {
          const phaseParameters: IndexedParameter[] = parameters?.filter((p) => p.display?.phaseId === pc.phaseId);
          if (phaseParameters.length) {
            const phaseInvalid =
              Object.keys(errors).length > 0
                ? errors.parameters?.filter((ep, index) => phaseParameters.map((p) => p.index).includes(index) && ep)
                : [];
            const phaseCurrent = pc.phaseId === currentPhaseId;
            const includesPhaseId = currentHotspot?.includes(pc.phaseId as string);
            const includesAnyPhaseParameter = phaseParameters
              ?.map((e) => e.parameterName)
              .some((item) => currentHotspot.includes(item));
            return (
              <li
                key={pc.phaseId as string}
                className="flex-1 cursor-pointer relative border-t border-gray-200"
                onClick={() => changePhase(pc.phaseId as string)}
              >
                <div className={classNames(phaseCurrent ? 'border-blue-600' : 'border-gray-200', 'border-b-2')}>
                  <div className="flex pl-5 pt-4 pb-4 pr-5 w-full">
                    <div
                      className={classNames(
                        !phaseInvalid?.length ? 'text-blue-600' : 'text-red-500',
                        'flex whitespace-nowrap text-md font-medium text-blue-600',
                      )}
                    >
                      {pc.phaseName}
                      {hotspot &&
                        ((includesPhaseId && includesAnyPhaseParameter) ||
                          (!includesPhaseId && includesAnyPhaseParameter)) && (
                          <HiMiniFire className="ml-1 mt-1 text-red-500" />
                        )}
                    </div>

                    <BiSolidError
                      className={classNames(!phaseInvalid?.length ? 'text-white' : 'text-red-400', 'ml-2 h-5 w-5')}
                    />
                  </div>
                </div>
                {stepIdx !== 0 ? (
                  <>
                    {/* Separator */}
                    <div className="absolute inset-0 left-0 top-0 hidden w-3 lg:block" aria-hidden="true">
                      <svg
                        className="h-full w-full text-gray-300"
                        viewBox="0 0 12 82"
                        fill="none"
                        preserveAspectRatio="none"
                      >
                        <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vectorEffect="non-scaling-stroke" />
                      </svg>
                    </div>
                  </>
                ) : null}
              </li>
            );
          }
        })}
      </ol>
      {displayConfigurations &&
        displayConfigurations?.map((dc: DisplayConfiguration) => {
          return (
            <div key={dc.phaseId} className={classNames(dc.phaseId !== currentPhaseId ? `hidden` : '')}>
              <CustomizationPhaseForm
                onFormChange={onFormChange}
                displayConfiguration={dc}
                onValidate={validate}
                parameters={parameters?.filter((p) => p.display?.phaseId === dc.phaseId) ?? []}
              />
            </div>
          );
        })}
    </div>
  );
}
