import React, { useContext, useEffect, useState } from 'react';
import { useCustomizationTemplateContext } from './CustomizationTemplateProvider';

type ContextProps = {
  algorithmType: string;
  setAlgorithmType: (string: string) => void;
  unitCalculationTimeBinary: number;
  unitCalculationTimeBrute: number;
  maxCombinationsBinary: number;
  maxCombinationsBrute: number;
  currentNumberOfCombinations: number;
  setCurrentNumberOfCombinations: (number: number) => void;
  currentNumberOfBinaryCombinations: number;
  setCurrentNumberOfBinaryCombinations: (number: number) => void;
  executionTimeBinary: string;
  setExecutionTimeBinary: (string: string) => void;
  executionTimeBrute: string;
  setExecutionTimeBrute: (string: string) => void;
  isWithinLimit: boolean;
  setIsWithinLimit: (boolean: boolean) => void;
  numericParameters: number;
};

const AdvisoryLimitationContext = React.createContext<ContextProps>({
  algorithmType: '',
  setAlgorithmType: () => {},
  unitCalculationTimeBinary: 0,
  unitCalculationTimeBrute: 0,
  maxCombinationsBinary: 0,
  maxCombinationsBrute: 0,
  currentNumberOfCombinations: 1,
  setCurrentNumberOfCombinations: () => {},
  currentNumberOfBinaryCombinations: 1,
  setCurrentNumberOfBinaryCombinations: () => {},
  executionTimeBinary: '',
  setExecutionTimeBinary: () => {},
  executionTimeBrute: '',
  setExecutionTimeBrute: () => {},
  isWithinLimit: false,
  setIsWithinLimit: () => {},
  numericParameters: 0,
});

export const AdvisoryLimitationProvider = ({ children }) => {
  const [algorithmType, setAlgorithmType] = useState('binary');
  const [currentNumberOfCombinations, setCurrentNumberOfCombinations] = useState(1);
  const [currentNumberOfBinaryCombinations, setCurrentNumberOfBinaryCombinations] = useState(1);
  const unitCalculationTimeBinary = 0.112;
  const unitCalculationTimeBrute = 0.066;
  const maxCombinationsBinary = Math.floor(180 / unitCalculationTimeBinary);
  const maxCombinationsBrute = Math.floor(180 / unitCalculationTimeBrute);
  const [executionTimeBinary, setExecutionTimeBinary] = useState('');
  const [executionTimeBrute, setExecutionTimeBrute] = useState('');
  const [numericParameters, setNumericParameters] = useState(0);
  const { combinations, categoricalParameters, hasCombinationTree } = useCustomizationTemplateContext();
  const [isWithinLimit, setIsWithinLimit] = useState(true);

  function divideUntilOne(number: number): number {
    let steps = 0;
    while (number > 1) {
      number = Math.round(number / 2);
      steps += 1;
    }
    if (steps >= 1) {
      return steps + 1;
    }
    return steps;
  }

  function calculateCurrentNumberOfBinaryCombinations(options: {}): number {
    let totalSteps = 0;
    let combinations = 1;
    let linkedParameters: number;

    for (const key in options) {
      if (options.hasOwnProperty(key)) {
        if (key !== 'linkedParameters') {
          if (categoricalParameters.includes(key)) {
            combinations *= options[key];
          } else {
            let steps = divideUntilOne(options[key]);
            totalSteps += steps;
          }
        }
      }
    }

    setNumericParameters(totalSteps);
    // this means that the combination tree is valid but there aren't constraints
    if (options['linkedParameters'] === undefined && hasCombinationTree) {
      linkedParameters = 1;
    } else {
      linkedParameters = options['linkedParameters'];
    }

    const totalCombinations = totalSteps * combinations * linkedParameters;
    return isNaN(totalCombinations) ? 0 : totalCombinations;
  }

  function calculateCurrentNumberOfCombinations(options: {}): number {
    if (hasCombinationTree) {
      return Object.values(options).reduce((acc, value) => acc * value, 1) as number;
    } else if (options['linkedParameters'] === 0 || options['linkedParameters'] === undefined) {
      return 0;
    }
  }

  function calculateEstimatedTime(estimatedTimeInSeconds) {
    if (estimatedTimeInSeconds < 0.5) {
      estimatedTimeInSeconds = 1;
    }
    const roundedTimeInSeconds = Math.round(estimatedTimeInSeconds);
    if (roundedTimeInSeconds >= 3600) {
      const hours = Math.floor(roundedTimeInSeconds / 3600);
      const remainingSeconds = roundedTimeInSeconds % 3600;
      const minutes = Math.floor(remainingSeconds / 60);
      const seconds = remainingSeconds % 60;
      return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} minute${minutes > 1 ? 's' : ''} ${seconds} second${
        seconds !== 1 ? 's' : ''
      }`;
    } else if (roundedTimeInSeconds >= 60) {
      const minutes = Math.floor(roundedTimeInSeconds / 60);
      const seconds = roundedTimeInSeconds % 60;
      return `${minutes} minute${minutes > 1 ? 's' : ''} ${seconds} second${seconds !== 1 ? 's' : ''}`;
    } else {
      return `${roundedTimeInSeconds} second${roundedTimeInSeconds !== 1 ? 's' : ''}`;
    }
  }

  useEffect(() => {
    // calculate the number of combinations for each algorithm type
    const newNumberOfCombinations = calculateCurrentNumberOfCombinations(combinations);
    let newNumberOfBinaryCombinations = calculateCurrentNumberOfBinaryCombinations(combinations);

    console.log(combinations);
    console.log('newNumberOfCombinations', newNumberOfCombinations);
    console.log('newNumberOfBinaryCombinations', newNumberOfBinaryCombinations);

    // update execution time of each algorithm
    const executionTimeBinary = calculateEstimatedTime(newNumberOfBinaryCombinations * unitCalculationTimeBinary);
    const executionTimeBrute = calculateEstimatedTime(newNumberOfCombinations * unitCalculationTimeBrute);

    setExecutionTimeBinary(executionTimeBinary ? executionTimeBinary : '0');
    setExecutionTimeBrute(executionTimeBrute ? executionTimeBrute : '0');

    // disable execution if the number of combinations exceeds the limit
    const isBinaryAlgorithm = algorithmType === 'binary';
    const isInLimit = isBinaryAlgorithm
      ? newNumberOfBinaryCombinations < maxCombinationsBinary
      : newNumberOfCombinations < maxCombinationsBrute;

    setIsWithinLimit(isInLimit);

    // update the state with the new values
    setCurrentNumberOfCombinations(newNumberOfCombinations);
    setCurrentNumberOfBinaryCombinations(newNumberOfBinaryCombinations);
  }, [algorithmType, combinations]);

  return (
    <AdvisoryLimitationContext.Provider
      value={{
        algorithmType,
        setAlgorithmType,
        unitCalculationTimeBinary,
        unitCalculationTimeBrute,
        maxCombinationsBinary,
        maxCombinationsBrute,
        currentNumberOfCombinations,
        setCurrentNumberOfCombinations,
        currentNumberOfBinaryCombinations,
        setCurrentNumberOfBinaryCombinations,
        executionTimeBinary,
        setExecutionTimeBinary,
        executionTimeBrute,
        setExecutionTimeBrute,
        isWithinLimit,
        setIsWithinLimit,
        numericParameters,
      }}
    >
      {children}
    </AdvisoryLimitationContext.Provider>
  );
};

export const useCalculationLimits = () => useContext(AdvisoryLimitationContext);
