import { RiDeleteBinLine, RiFileCopyLine } from 'react-icons/ri';
import { LiaFileImportSolid } from 'react-icons/lia';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStateMachine } from 'little-state-machine';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast/headless';
import { BsFiletypeJson } from 'react-icons/bs';
import { debounce } from 'lodash';
import { JsonEditor } from 'jsoneditor-react';
import 'jsoneditor-react/es/index';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  ImpactMethod,
  ModeledProcessInfoDto,
  TreeInfoDto,
  useGetParametersForTreesQuery,
} from '../../../../api/lightweightOlcaCore/lightweightOlcaCoreApi';
import { PhaseDto } from '../../../../api/project/projectApi';
import { Button } from '../../../../components/Elements';
import initScenarioTemplateAction from '../../utils/initScenarioTemplateAction';
import { InputText } from '../../../../components/input';
import { getInputError } from '../../../../lib/getInputError';
import {
  AssessmentConfiguration,
  BasicScenarioTemplate,
  DisplayConfiguration,
  usePostScenarioTemplateMutation,
} from '../../../../api/scenarioTemplate/scenarioTemplateApi';
import { TextArea } from '../../../../components/input/TextArea';
import { Parameter, useGetCustomizationSpacesQuery } from '../../../../api/customizationSpace/customizationSpaceApi';
import { generateParameters } from '../../utils/generateParameters';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { checkImportMapping, ImportMapping } from '../../utils/checkImportMapping';
import Modal from '../../../../components/Modal/Modal';
import PhaseTreeMapping from './PhaseTreeMapping';
import AssessmentConfigurationForm from './AssessmentConfigurationForm';
import { InputList } from '../../../../components/input/InputList';
import { basicScenarioTemplateSchema } from '../validation/basicScenarioTemplateSchema';
import { SkipTests } from '../validation/skipTest';
import { Checkbox } from '../../../../components/input/Checkbox';
import { Show } from '../../../../components/show/Show';

type ScenarioTemplateFormProps = {
  initialValues: any;
  phases: PhaseDto[];
  trees: TreeInfoDto[];
  impactMethods: ImpactMethod[];
};

function ScenarioTemplateForm(props: ScenarioTemplateFormProps) {
  const { initialValues, phases, trees, impactMethods } = props;

  const navigate = useNavigate();
  const [selectedTreeIds, setSelectedTreeIds] = useState<string[]>([]);
  const [importJson, setImportJson] = useState('');
  const [importMapping, setImportMapping] = useState<ImportMapping[]>([]);
  const [importModal, setImportModal] = useState(false);
  const [editJson, setEditJson] = useState(false);
  const { actions, state } = useStateMachine({ initScenarioTemplateAction });

  const { data: customizationSpaces } = useGetCustomizationSpacesQuery({
    customizationSpaceRefIds: undefined,
  });
  const { data: variables } = useGetParametersForTreesQuery(
    selectedTreeIds.length > 0 ? { treeIds: selectedTreeIds.filter(Boolean) } : skipToken,
  );

  const methods = useForm({
    defaultValues: initialValues,
    resolver: (values, context, options) => {
      return yupResolver(basicScenarioTemplateSchema)(
        values,
        {
          ...values,
          skipTests: [
            // SkipTests.PARAMETER_DISPLAY_TEST,
            // SkipTests.DEPENDS_ON_FORMULA_PARAMETER_TEST,
            SkipTests.VARIABLE_MAPPING_TEST,
            // SkipTests.LINKED_PARAMETERS_TEST,
            // SkipTests.REMOTE_FLOW_TEST,
            // SkipTests.FLOW_SWITCH_TEST,
            SkipTests.DISPLAY_TEST,
            SkipTests.PARAMETER_TEST,
          ],
        },
        options,
      );
    },
  });

  const {
    register,
    control,
    reset,
    formState: { errors },
    getValues,
    handleSubmit,
  } = methods;

  const handleNavigate = (values: BasicScenarioTemplate) => {
    const template = createTemplate(values);
    actions.initScenarioTemplateAction({ template });
    navigate('./../customization');
  };

  const handleImportJson = async () => {
    const mapping: any[] = checkImportMapping(importJson, trees, phases);
    if (mapping.length > 0) {
      setImportModal(true);
      setImportMapping(mapping);
    } else {
      reset(JSON.parse(importJson));
    }
  };

  const mapImportJson = (importJson: any) => {
    reset(JSON.parse(importJson));
    setImportModal(false);
  };

  const createTemplate = (values: any, forCopy: boolean = false) => {
    if (!customizationSpaces) return;
    const template: any = {
      ...values,
      id: !values.id || forCopy ? undefined : values.id,
      type: 'BASIC',
    };
    const parameters: Parameter[] = template.parameters ?? [];
    const displayConfigurations: DisplayConfiguration[] =
      template.displayConfigurations ?? ([] as DisplayConfiguration[]);
    const variableList = Object.entries(variables ?? {}).reduce((acc, [key, vars]) => {
      return [...acc, ...vars];
    }, []);

    const vars: any[] =
      template.variableConfigurations?.filter((vc) =>
        variables ? variableList.map((v) => v.name).includes(vc.name) : [],
      ) ?? [];

    values.assessmentConfigurations?.forEach((ac: AssessmentConfiguration, index: number) => {
      ac.phaseConfigurations?.forEach((pc) => {
        //Still noit fully configured so we skip the displayConfigurations creation
        if (!pc.phaseId || !pc.treeId) return;

        let dc = displayConfigurations.find((dc) => dc.phaseId === pc.phaseId);
        const tree = trees.find((x) => x.id === pc.treeId);

        pc.phaseName = phases.find((p) => p.id === pc.phaseId)?.name;
        pc.treeName = tree?.name;
        const cSpaces = [
          ...(tree?.subProcesses?.map((x: ModeledProcessInfoDto) => x.id as string) ?? []),
          tree?.processRefId as string,
        ];

        vars.push(...(variables?.[pc.treeId] ?? []));
        if (!dc) {
          const g = generateParameters(customizationSpaces, cSpaces, pc, tree?.processRefId);

          dc = {
            customizationSpaces: [...cSpaces],
            tabs: [...g.tabs],
          };

          displayConfigurations.push({
            ...dc,
            phaseId: pc.phaseId,
            treeIds: [pc.treeId as string],
            phaseName: phases.find((p) => p.id === pc.phaseId)?.name,
          });

          parameters.push(...g.parameters);
        } else if (dc && !dc.treeIds?.includes(pc.treeId as string)) {
          const g = generateParameters(customizationSpaces, cSpaces, pc, tree?.processRefId);
          dc.customizationSpaces = [...(dc.customizationSpaces ?? []), ...cSpaces];
          dc.tabs = [...new Map([...(dc.tabs ?? []), ...g.tabs]?.map((item) => [item['name'], item])).values()];

          dc.treeIds = [...(dc.treeIds ?? ([] as string[])), pc.treeId];
          parameters.push(...g.parameters);
        }
      });
    });

    const mappedVariables = new Map();

    vars.forEach((v) => {
      if (mappedVariables.has(v.name)) {
        const mapEntry = mappedVariables.get(v.name);
        if (!mapEntry.parameterName && v.parameterName) {
          mappedVariables.set(v.name, mapEntry);
        }
      } else {
        mappedVariables.set(v.name, v);
      }
    });

    template.displayConfigurations = displayConfigurations;
    template.parameters = [...new Map(parameters?.map((item) => [item['parameterName'], item])).values()];
    // DISTINCT
    template.variableConfigurations = [...mappedVariables.values()].map((v: any) => {
      //Initial assignment of parameter to variable base on name
      const par = template.parameters.filter((p) => p.parameterName === v.name);
      if (par.length === 1 && !v.parameterName) {
        return { ...v, parameterName: par[0].parameterName };
      }
      return v;
    });
    console.log(template);
    return template;
  };

  const handleAssessmentTreeChange = (assessmentTrees: string[]) => {
    setSelectedTreeIds((prevValue: string[]) => {
      const trees = new Set(prevValue);
      assessmentTrees.forEach((at) => trees.add(at));
      return [...trees];
    });
  };

  const debouncedClickHandler = debounce((values) => reset(values), 1000);

  // console.log(updatedCustomizationSpace);

  return (
    <FormProvider {...methods}>
      <div className="shadow sm:rounded bg-white p-4 mt-3">
        <form
          onSubmit={handleSubmit(handleNavigate, (error, value) => {
            console.error(error, getValues());
          })}
          className="flex flex-col"
        >
          <div className="my-4 flex justify-around">
            <input type="text" {...register('id')} hidden={true} />
            <InputText
              className="pr-2 flex-1"
              label={'Scenario template name'}
              error={getInputError(errors, 'name')}
              {...register('name')}
            />
            <InputText className="pl-2 flex-1" label={'Description'} {...register('description')} />
            <Checkbox label={'Visible'} className={'col-span-1 mx-4 flex-shrink w-16'} {...register(`visible`)} />
          </div>
          <InputList
            control={control}
            item={{}}
            addButtonLabel={'Add assessment configuration'}
            name={'assessmentConfigurations'}
            renderItem={(field, index, remove) => (
              <div className="flex col-span-12 flex-col border-2 my-2 pb-4 px-4">
                <div className=" flex-1 flex my-4 p-2 rounded">
                  <AssessmentConfigurationForm
                    className="flex-1"
                    phases={phases}
                    trees={trees}
                    onTreeChange={handleAssessmentTreeChange}
                    impactMethods={impactMethods}
                    prefix={`assessmentConfigurations.${index}`}
                  />
                </div>
                <RiDeleteBinLine className="h-full w-8 text-red-500 self-center mx-2" onClick={() => remove(index)} />
              </div>
            )}
          />

          <div className="mt-6">
            <Button type="submit" className="float-right">
              Add Customization
            </Button>

            <div className="flex">
              <CopyToClipboard
                text={JSON.stringify(createTemplate(getValues(), true))}
                onCopy={() => toast.success('Copied to clipboard')}
              >
                <Button variant="none" className="flex text-sm">
                  <RiFileCopyLine className="w-6 h-6" /> Copy as JSON
                </Button>
              </CopyToClipboard>

              <Button
                variant="none"
                className="flex border-l-2 rounded-none text-sm"
                onClick={() => setImportJson('{}')}
              >
                <LiaFileImportSolid className="w-6 h-8" /> Import from JSON
              </Button>

              <Button
                variant="none"
                className="flex border-l-2 rounded-none text-sm"
                onClick={() => setEditJson(!editJson)}
              >
                <BsFiletypeJson className="w-6 h-8" /> Edit JSON
              </Button>
            </div>

            <Show>
              <Show.When isTrue={!!importJson}>
                <TextArea rows={10} onChange={(e) => setImportJson(e.target.value)} />
                <Button onClick={handleImportJson}>Import</Button>
              </Show.When>
            </Show>
            <Show>
              <Show.When isTrue={editJson}>
                <JsonEditor value={getValues()} onChange={debouncedClickHandler} />
              </Show.When>
            </Show>
          </div>
        </form>
      </div>
      <Modal size="lg" open={importModal} handleOpen={handleImportJson}>
        <div className="my-12">
          <PhaseTreeMapping
            phases={phases}
            trees={trees}
            importMapping={importMapping}
            importJson={importJson}
            onImportJsonChange={mapImportJson}
          />
        </div>
      </Modal>
    </FormProvider>
  );
}

export default ScenarioTemplateForm;
